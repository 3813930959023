.tournamentHeader {
  background-color: #383838;
  text-transform: uppercase;
  color: #e8e8e8;
  font-family: Roboto;
  font-size: 0.8em;
}

.tournamentHeader .subtitle {
  color: #aaa;
  font-size: 0.6em;
  font-weight: bolder;
}

.payoutsHeader {
  background-color: #f6f6f6;
  color: #aaa;
  text-transform: uppercase;
  font-size: 0.7rem;
}
