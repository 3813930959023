.flash-message {
  border-radius: 10px;
  position: fixed;
  top: 80px;
  right: 10px;
  padding: 12px;
  display: flex;
  align-items: center;
  z-index: 1111;
  border: 1px solid;
}

.flash-error {
  background: lightcoral;
  border: 1px solid red;
}

.flash-success {
  background: #CDF9DA;
  border: 1px solid green;
}
