@media (max-width: 991px) {
  .topNavWrapper .leagueNavContainer {
    -webkit-flex-direction: column;
    flex-direction: column;
    height: auto;
    line-height: 32px;
  }
  .topNavWrapper .leagueNavItem_link.leagueNavItem_link__active {
    border-bottom: none;
  }
}

.topNav {
  background-color: #34495e;
  color: #ffffff;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3);
}

.topNavContainer {
  background-color: #34495e;
  width: 100%;
}

.topNavTitle {
  font-family: 'Archivo', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 0.86;
  text-align: left;
  margin-bottom: 0px;
}

.topNavTitle_majors {
  color: #2ecc71;
}

.topNavTitle_challenge {
  color: #ffffff;
}

.topNavProfile {
  color: #94a9be;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid #94a9be;
  padding: 0.3rem 0.3rem;
  border-radius: 4px;
  width: 83px;
}

.topNavProfile:hover {
  color: #ffffff;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.topNavProfile_label {
  font-family: 'Roboto Condensed', 'Roboto', 'sans-serif';
  font-size: 0.8rem;
  letter-spacing: 0.3px;
  padding-left: 0.25rem;
}

.topNavProfile_chevron {
  font-size: 0.75rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.navbar-dark .topNav_toggler.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .topNav_toggler.navbar-toggler > .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.leagueDropdownContainer {
  white-space: nowrap;
}

.leagueDropdownContainer > .dropdown-menu.show {
  top: 75%;
  display: inline-block;
}

.leagueDropdown,
.leagueDropdown:hover,
.leagueDropdown:active,
.leagueDropdown:focus {
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  letter-spacing: 0.5px;
  line-height: 42px;
}

.leagueNavContainer {
  display: -webkit-flex;
  -webkit-flex-direction: row;
  display: flex;
  flex-direction: row;
  /* text-align: center; */
  height: 60px;
  line-height: 64px;
}

.leagueNavItem {
  display: inline-block;
  font-family: 'Roboto Condensed', 'Roboto', 'sans-serif';
  font-size: 0.9rem;
  font-weight: normal;
  letter-spacing: 0.6px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

a.leagueNavItem_link {
  color: #94a9be;
  text-decoration: none;
}

.leagueNavItem_link.leagueNavItem_link__active,
a.leagueNavItem_link:active,
a.leagueNavItem_link:hover,
a.leagueNavItem_link:focus {
  color: #ffffff;
  text-decoration: none;
}

.leagueNavItem_link.leagueNavItem_link__active {
  border-bottom: 4px solid #3498db;
}

.btn-no-border {
  border: none !important;
}

.btn-only-buttom-border {
  border: none !important;
  border-bottom: 1px solid !important;
}
