.landingHeader {
  background-image: linear-gradient(to bottom, rgba(52,73,94,0.9), rgba(52,73,94,0.9)), url('../img/golfcourse.jpg');
  background-repeat: no-repeat;
  background-position: top, top -50px center;
  background-size: auto;
  color: #ffffff;
  height: 60px;
  z-index: 10;
}

.landingHeaderTitle {
  font-family: 'Archivo', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 0.86;
  text-align: left;
  margin-bottom: 0px;
}

.landingHeaderTitle_majors {
  color: #2ecc71;
}

.landingHeaderTitle_challenge {
  color: #ffffff;
}

.landingHeader.landingHeaderTransparent {
  background: none !important;
  background-color: rgba(0,0,0,0);
}

.landingHeaderLink {
  font-family: "Roboto";
  font-size: 15px;
  line-height: 16px;
  color: #b0bec5;
  white-space: nowrap;
}

.landingHeaderLink:hover {
  color: #ffffff;
  text-decoration: none;
}

.landingHeaderSignup {
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  background-color: #21cc71;
}

.landingHeaderSignup:hover {
  background-color: #28a745;
}

.landingHeader a {
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  letter-spacing: 0.6px;
  color: #94a9be !important;
  text-transform: uppercase;
  white-space: nowrap;
}

.landingHeader a:hover {
  color: #ffffff !important;
  text-decoration: none;
}
