.react-confirm-alert-body > h1 {
    margin-top: 0;
    font-size: 20px;
    font-weight: bold;
}

.text-responsive {
  font-size: calc(5% + 1vw + 1vh);
}

.react-confirm-alert-overlay{
  z-index: 9999;
}



.react-confirm-alert-button-group>button:first-child{
  color: black ;
  background-color: #e8e8e8 ;
}

.react-confirm-alert-button-group button.button_primary{
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}



.react-confirm-alert-button-group button.btn1 {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-confirm-alert-button-group button.button_success{
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.react-confirm-alert-button-group button.btn_outline_success {
    color: #28a745;
    background-color: transparent;
    border: 1px solid transparent;
    border-color: #28a745;
}
