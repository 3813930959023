
.completedEntryCollapse {
}

.table.completedEntriesTable {
  margin: 0;
  background-color: rgba(52, 152, 219, 0.13);
}

.completedEntriesTableHeader {
  color: #a1a1a1;
  font-size: 0.8rem;
}

.completedEntryRow {

}
