.eventTitle {
  font-family: Roboto;
  font-size: 2rem;
}

.eventTitle_sub {
  color: #999;
  font-weight: 300;
  font-size: 1.5rem;
}

.eventTitle_sub_sub {
  color: #999;
  font-weight: 300;
  font-size: 1rem;
}

.contestlederboard .eventTitle {
  font-family: inherit;
  font-size: 1.4rem;
}

.contestlederboard .eventTitle_sub {
  font-size: 1.0rem;
}




@media (max-width: 575px) {
  .eventTitle {
    font-size: 1.55rem;
  }

  .eventTitle_sub {
    font-size: 1.15rem;
  }
}

.oadField .eventTitle {
  font-size: 1.3rem;
}

.oadField .eventTitle_sub {
  font-size: 1rem;

}

.oadField .eventTitle_sub_sub {
  font-size: 1rem;
}
