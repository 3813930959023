.mcPubContainer {
  background-color: #ecf0f1;
}

#root {
  min-height: 100vh;
}

.onboardingBg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  min-height: 920px;
  background-image: linear-gradient(to bottom, rgba(52,73,94,0.9), rgba(52,73,94,0.9)), url('../img/golfcourse.jpg');
  background-repeat: no-repeat;
  background-position: top, top -50px center;
  background-size: auto;
  z-index: 0;
}
