.landingCard {
  position: relative;
  padding: 1rem 1rem;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.3);
  z-index: 10;
  color: #212121;
}

.landingHeroBg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 728px;
  background-image: linear-gradient(to bottom, rgba(52,73,94,0.9), rgba(52,73,94,0.9)), url('../../img/golfcourse.jpg');
  background-repeat: no-repeat;
  background-position: top, top -50px center;
  background-size: auto;
  z-index: 0;
}

.landingHeroBg.promo {
  height: 508px;
}

.landingHero {
  position: relative;
  height: calc(728px - 60px - 2rem);
  padding-top: 2rem;
}

.landingHero.promo {
  height: calc(508px - 60px - 2rem);
}

.landingHero_title {
  font-family: "Vollkorn";
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  color: #ffffff;
  white-space: nowrap;
}

.landingHero_title.club {
  font-size: 42px;
  line-height: 52px;
  font-weight: 400;
  white-space: normal;
}

.landingHero_subtitle {
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
  color: #b0bec5;
}

.landingHero_details {
  font-family: "Roboto";
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
  color: #b0bec5;
}

.landingHero.container {
  padding-left: 0px;
}

.landingHero_laptop {
  position: relative;
}

.landingHero_laptop__img {
  width: 100%;
}

.landingHero_laptop__img.promo {
  width: 80%;
}

.landingHeroSignup {
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: 500;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 17rem;
  background-color: #21cc71;
}

.landingHeroSignup:hover {
  background-color: #28a745;
}


.landingHeroSignup__alt {
  font-size: 0.9rem;
  font-family: "Roboto";
  font-weight: 500;
  border: none;
  background-color: #ffffff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  /* margin-top: rem; */
  margin-bottom: 2rem;
  width: 17rem;
}

.landingContainer {
  padding-left: 0px;
  padding-top: 2rem;
}

.landingSection1 {
  background-color: #ecf0f1;
  position: relative;
  width: 100%;
}


.landingSectionTitle__dark {
  font-family: "Vollkorn";
  font-size: 30px;
  font-weight: 700;
  line-height: 2.4;
  color: #212121;
}

.landingSectionTitle__white {
  font-family: "Vollkorn";
  font-weight: 700;
  font-size: 30px;
  line-height: 2.4;
  color: #ffffff;
}

.landingBD_image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.landingBD_title {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 18px;
  color: #212121;
  line-height: 22px;
  text-align: center;
}

.landingBD_text {
  font-family: "Roboto";
  font-size: 14px;
  color: #78909c;
  line-height: 22px;
  text-align: center;
}

.landingSection2 {
  background-color: #3498db;
}

.landingSection3 {
  background-color: #21cc71;
}

.landingSection4 {
  background-color: #ffffff;
}

.landingHero_phone {
  position: relative;
}

.landingHero_phone__img {
  width: 100%;
}

.landingSubtitle__white {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.landingText__white {
  font-family: "Roboto";
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
}

.landingText__white a {
  color: #ffffff;
  text-decoration: underline;
}

.landingStepNumberCircle {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Roboto";
  display: inline-block;
  height: 2em;
  width: 2em;
  line-height: 2em;

  -moz-border-radius: 1em;
  border-radius: 1em;

  background-color: #ffffff;
  border-color: #ffffff;
  color: #3498db;

  border: 1px solid #3498db;
  text-align: center;
  position: relative;
  top: -0.25em;
}

.landingSection_smalltitle {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: bold;
  line-height: 36px;
  letter-spacing: 0.6px;
  color: #90a4ae;
  text-transform: uppercase;
}

.landingSection_ctatitle {
  font-size: 32px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 1px;
  color: #ffffff;
}

.landingSection_ctabutton {
  font-size: 0.9rem;
  font-family: "Roboto";
  font-weight: 500;
  border: none;
  background-color: #ffffff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 2rem;
  width: 17rem;
}

.clubHeader_ctabutton {
  font-size: 0.9rem;
  font-family: "Roboto";
  font-weight: 500;
  border: none;
  background-color: #ffffff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-transform: uppercase;
  color: #4e6bf5;
}

.clubHeader_ctabutton:hover {
  background-color: #78909c;
}



.landingJumboQuotes {
  position: relative;
  font-size: 44px;
  color: #cfd8dc;
  top: -20px;
}

.landingQuote {
  font-family: "Vollkorn";
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.38;
  color: #78909c;
}

.landingPromoEvent_name {
  font-size: 2rem;
  font-family: "Roboto";
  font-weight: 300;
  color: #2c3e50;
  padding-bottom: 2rem;
}

.landingPromoEvent_cell {
  text-align: center;
  white-space: nowrap;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.landingPromoEvent_250K {
  text-align: center;
  font-weight: 800;
  white-space: nowrap;
  padding-bottom: 0.5rem;
}


.landingPromoEvent_celldata {
  font-size: 1.15rem;
  font-family: "Roboto";
  font-weight: 300;
  color: #2c3e50;
}

.landingPromoEvent_celllabel {
  font-size: 0.85rem;
  font-family: "Roboto";
  font-weight: 500;
  color: #78909c;
  text-transform: uppercase;
}

.landingPromoEvent_cta {
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.8rem 3rem;
  background-color: #21cc71;
}

.landingPromoEvent_cta:hover {
  background-color: #28a745;
}

.clubLandingHeroBg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 700px;
  background-image: linear-gradient(to bottom, rgba(52,73,94,0.9), rgba(52,73,94,0.9)), url('../../img/swinging2.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto;
  z-index: 0;
}


.clubLandingHero {
  position: relative;
  height: calc(700px - 60px - 2rem);
}

.clubLandingHeroForm {
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 2px 18px 0 rgba(31, 43, 47, 0.5);
  background-color: #ffffff;
}

.clubIntakeForm {

}

.clubIntakeForm_title {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 24px;
  color: #212121;
  line-height: 22px;
  text-align: center;
}

.clubIntakeForm_subtitle {
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #78909c;
}

.clubIntakeForm .form-control {
  width: 100%;
  border: 2px solid #eceff1;
}

.clubIntakeForm .form-control::placeholder {
  font-family: "Roboto";
  color: #90a4ae;
}

.clubIntakeForm_submit {
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: 500;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  width: 100%;
  background-color: #2ecc71;
  text-transform: uppercase;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  border: none;
}

.clubIntakeForm_submit:hover:enabled {
  background-color: #28a745;
}


@media (max-width: 575px) {
  .landingHero.container {
    padding-left: 15px;
  }
  .landingContainer {
    padding-left: 15px;
  }

}

@media (max-width: 767px) {
  .landingHero_title.club {
    font-size: 28px;
    line-height: 36px;
  }
  .landingJumboQuotes {
    top: -10px;
  }
  .landingHero_subtitle{
    font-size: .87rem;
  }
}

@media (max-width: 1199px) {
  .landingHero_subtitle{
    font-size: .85rem;
  }

}

.promoBold {
  font-weight: bold;
  font-size: 22px;
}

.pgaLogo{
  height: 70px;
  margin-left: -9px;
}

.jdmeLogo{
  height: 65px;
  margin-left: -9px;
}
