
.cupTeamRowRank.winner {
  font-size: 0.9em;
  /* font-weight: bold; */
  display: inline-block;
  height: 2em;
  width: 2em;
  line-height: 2em;

  -moz-border-radius: 1em;
  border-radius: 1em;

  background-color: #2ecc71;
  border-color: #2ecc71;
  color: #ffffff;

  border: 1px solid #d9dde0;
  text-align: center;
}

.cupTeamRowRank:not(.winner) {
  font-size: 0.9em;
    /* font-weight: bold; */
}

.cupTeamRowBody.personal {
  background-color: rgba(52, 152, 219, 0.4);
}

.cupTeamRowBase {
  font-family: "Roboto";
  color: #515356;
  font-weight: normal;
  white-space: nowrap;
  vertical-align: middle!important;
}
