@import url('https://fonts.googleapis.com/css?family=Archivo:700|Roboto|Roboto+Condensed|Vollkorn:400|Vollkorn:700|Roboto:500|Roboto:900|Roboto:300');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-overflow-scrolling: touch;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}


.or-divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #666;
  font-weight: bold;
  font-size: 1rem;
  margin: 1rem 0;
}

.or-divider::before,
.or-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
  margin: 0 10px;
}

.btn-light-red {
  color: #fff !important;
  background-color: #dc816e !important;
  border-color: #dc816e !important;
}

.btn-light-red:hover{
  background-color: #d06650 !important;
}

.btn-light-red-outline {
  color: #dc816e !important;
}

.card-header{
  border-left: 8px solid green;
}

.vertical-text {
   writing-mode: vertical-rl; /* Vertical text, left-to-right */
   text-orientation: mixed;
   background-color: green;
   color: white;
   display: initial;
   font-size: .7em;
 }

.vertical-text-td
 {
    background-color: green;
    color: white;
    text-align: center;
    vertical-align: middle;

}


.no-border-top{
  border-top: 0px !important;
}

.light-border-t{
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.light-border-b{
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.light-border-lr{
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.light-border-tb{
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
