@media screen and (max-width: 600px) {
  .golfer-headshot-container-big-holder{
    width: 98% !important;
  }

  .golfer-headshot-container-big{
    height: 70px !important;
    width: 70px !important;
  }

  .golfer-headshot-big{
    height: 55px !important;
    width: 55px !important;
  }

  .golfer-headshot-alt{
    margin-left: 42px !important;
    margin-top: -14px !important;
  }
}

.leaguePromoImageContainer {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: calc(222.0 / 600.0 * 100%);
  overflow: hidden;
}

.leaguePromoImageAspect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.golfer-headshot-container-big-holder{
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  width: 88%;
}

.golfer-headshot-container-big{
  height: 90px;
  width: 90px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%), #CFD4D9;
  border: 3px solid #FFFFFF;
  border-radius: 50px;
  padding: 4px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.golfer-headshot-big{
  height: 75px;
  width: 75px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  color: transparent;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
}

.golfer-headshot-mid{
  height: 55px;
  width: 55px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  color: transparent;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
}

.golfer-headshot-small{
  height: 40px;
  width: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  color: transparent;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
}

.roster-headshots-name{
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #272A2E;
  margin: 8px 0px;
  white-space: nowrap;
  cursor: pointer;
}

.golfer-name-initial{
  color: #38485C;
  font-size: 30px;
}

.golfer-name-initial div{
  margin-left: auto;
  margin-right: auto;
}

.golfer-headshot-alt{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /*padding: 10px 5px;*/


  width: 32px;
  height: 14px;
  margin-top: -17px;
  margin-left: 58px;

  background: #38485C;
  border-radius: 6px;
  color: #FFFFFF;
  /*border: 2px dashed #38485C;
  box-sizing: border-box;*/
}

.alt{
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 9px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 5px;
}


.table.contestLBDetailsTable {
  margin: 0;
  background-color: rgba(52, 152, 219, 0.13);
}

.contestLBDetailsHeader {
  color: #a1a1a1;
  font-size: 0.8rem;
}
