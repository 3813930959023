.rulesContent {
}

.rulesContent .row {
  padding-bottom: 1rem;
}

.rulesContent h5 {
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 900;
  line-height: 22px;
  color: #212121;
}

.rulesContent h6 {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: bold;
  line-height: 22px;
  color: #212121;
}

.rulesContent p, .rulesContent li {
  font-size: 14px;
  font-family: "Roboto";
  line-height: 22px;
  color: #78909c;
}

.rulesContentTitle {
  font-family: "Vollkorn", serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.ruleStepNumberCircle {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Roboto";
  display: inline-block;
  height: 2em;
  width: 2em;
  line-height: 2em;

  -moz-border-radius: 1em;
  border-radius: 1em;

  background-color: #2ecc71;
  border-color: #2ecc71;
  color: #ffffff;

  border: 1px solid #d9dde0;
  text-align: center;
  position: relative;
  top: -0.25em;
}

.richTextEditor p{
  line-height: normal !important;
}

.richTextEditor p span{
  margin-bottom: 3px;
}

.foundationContent p, .foundationContent li {
  font-size: 14px;
  font-family: "Roboto";
  line-height: 22px;
}

.foundationContent li {
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .ruleStepNumberCircle {
    left: -0.5em;
  }
}
