.promoBannerDesktop{
    height:70px;
    width: 100%; /* fall back */
    background-position:center center;
    /*background-image:url(https://media.majorschallenge.com/leaderboardAdvBanner/regularLeagueDesktop.png);*/
    background-repeat:no-repeat;
    -webkit-background-size:contain;
    -moz-background-size:contain;
    -o-background-size:contain;
    background-size:contain;
}

.promoBannerMobile{
    height:70px;
    width: 100%; /* fall back */
    background-position:center center;
    /*background-image:url(https://media.majorschallenge.com/leaderboardAdvBanner/regularLeagueMobile.png);*/
    background-repeat:no-repeat;
    -webkit-background-size:contain;
    -moz-background-size:contain;
    -o-background-size:contain;
    background-size:contain;
}

.linkComponent {
  cursor: pointer;
  color: #007bff;
}

.linkComponent:hover {
  text-decoration: underline;
  /* color: #00008b; TODO this is incorrect, and this value does not appear to be standard across OS/browser */
}
