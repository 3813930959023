.step {
    text-align: center;
    font-weight: bolder;
    color: #999;
    width: calc(100% / 3);
}

.step.activeStep {
    color: #28a745;
}

.stepIndex {
    display: block;
    height: 3em;
    width: 3em;
    line-height: 2.8em;

    moz-border-radius: 50%;
    border-radius: 50%;

    border: 2px solid #999;
    color: #999;
}

.stepIndex:hover {
  cursor: pointer;
}

.activeStep .stepIndex {
    border: 2px solid #28a745;
    background-color: #28a745;
    color: #fff;
}

.stepTitle {
    font-size: .7em;
    text-transform: uppercase;
}

.nextButton {
    text-transform: uppercase;
    /* color: #e8e8e8; */
    /* background-color: #666; */
    /* height: 3em; */
}

.contentTitle {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: #333;
    font-size: 1.25em;
}

.charityContent .contentTitle {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: #333;
    font-size: 1.1em;
}

.poolOption {
    color: #666;
    background-color: #eeeeee;
}

.poolOption:active, .poolOption.active {
    color: #e8e8e8;
    background-color: #666;
}

@media (max-width: 991px) {
  .poolOption {
    font-size: 0.75rem;
  }
}
