.loginModal form label {
  width: 100%;
}

.loginModal form label input {
  width: inherit;
}

.btn.btn-outline-success.loginButton {
  border-color: #90a4ae;
  color: #b0bec5;
}

.btn.btn-outline-success.loginButton:hover {
  background-color: #90a4ae;
  color: #ffffff;
}

.btn.btn-outline-success.loginButtonActivation {
  padding-left: 50px;
  padding-right: 50px;
}

.btn.btn-outline-success.loginButtonActivation:hover {

}
