@media (min-width: 992px) {
  .dashboard .col-lg-auto {
    min-width: 700px;
  }
}

@media (min-width: 768px){
.col-md-8 {
    flex: 0 0 95% !important;
    max-width: 95% !important;
  }
}

@media (min-width: 770px){
.col-md-8 {
    flex: 0 0 66.666667% !important;
    max-width: 66.666667% !important;
  }
}



.dashboard {
  font-size: .85em;
}

.dashboardPromoImageContainer {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: calc(138.0 / 414.0 * 100%);
  overflow: hidden;
}

.dashboardPromoImageAspect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
