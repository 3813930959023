.pickTeam.container-fluid {
  max-width: 1128px;
  padding-left: 0px;
  padding-right: 0px;
}

.pickTeamContainer.container-fluid {
  max-width: 1080px;
  padding-left: 0px;
  padding-right: 0px;
}

.pickTeamHeaderContent {

}

.pickTeamMainContent {
  flex-wrap: nowrap;
  display: flex;
  overflow: auto;
}

.teamSlotEmpty {
  min-width: 150px;
  height: 64px;
  background-color: #eee;
  color: lightgray;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  border: 1px solid #d9dde0;
}

.teamSlotEmpty.teamSlotAlternate {
  opacity: 0.6;
  border-style: dashed;
}

.teamSlotEmpty_icon {
}

.teamSlotEmpty_text {
  padding-left: 1rem;
  color: #94a1ab;
  line-height: 64px;
}

.teamSlotEmpty.teamSlotAlternate .teamSlotEmpty_text {
  color: dimgray;
}

.teamSlotGolfer {
  min-width: 150px;
  height: 64px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  border: solid 1px #2ecc71;
}

.teamSlotGolfer.teamSlotAlternate {
  color: #94a1ab;
  border-style: dashed;
}

.teamSlotGolfer_img {
  height: 62px;
}

.teamSlotGolfer.teamSlotAlternate .teamSlotGolfer_img {
  opacity: 0.6;
}

.teamSlotEmpty .teamSlotGolfer_img {
  height: 62px;
}

.teamSlotGolfer_name {
  margin-top: 0.6rem;
  margin-bottom: 0.1rem;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.teamSlotGolfer_name:hover {
  cursor: pointer;
  color: blue;
}

.teamSlotGolfer_name.teamSlotGolfer_name__short {
  max-width: 148px
}

.teamSlotGolfer_name_option {
  margin-top: 0.6rem;
  margin-bottom: 0.1rem;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}



.teamSlotGolfer_meta {
  font-size: 0.70rem;
  white-space: nowrap;
  overflow: visible;
  color: #94a1ab;
}

.teamSlotGolfer_minus {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 26px;
  font-weight: 100;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #e74c3c;
  color: #e74c3c;
  line-height: 21px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.teamSlotGolfer_minus:hover {
  cursor: pointer;
  background-color: #e74c3c;
  color: #ffffff;
}


.availableGolfer {
  min-width: 150px;
  height: 64px;
  border-bottom: 1px solid gray;
  padding-left: 0.8rem;
  padding-right: 0.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  margin-bottom: 0.5rem;
}

.availableGolfer_name {
  margin-bottom: 0.1rem;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
}

.availableGolfer_name:hover {
  cursor: pointer;
  color: blue;
}

.availableGolfer_meta {
  color: #999;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: visible;
}

.availableGolfer_plus {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 26px;
  font-weight: 100;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #27ae60;
  color: #27ae60;
  line-height: 21px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.availableGolfer_plus:hover {
  cursor: pointer;
  background-color: #27ae60;
  color: #ffffff;
}

.availableGolfer_minus {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 26px;
  font-weight: 100;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #e74c3c;
  color: #e74c3c;
  line-height: 21px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.availableGolfer_minus:hover {
  cursor: pointer;
  background-color: #e74c3c;
  color: #ffffff;
}



@media (max-width: 991px) {
  .teamSlotEmpty_text {
    font-size: 0.9rem;
  }

  .availableGolfer_name {
    font-size: 0.9rem;
  }
  .teamSlotGolfer_meta {
    font-size: 0.6rem;
  }
}

.pickTeamEntries {
}

.pickTeamEntriesCell {

}

.pickTeamEntriesCell_team {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pickTeamEntriesCell_roster {
  font-size: 0.75rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.deleteEntryIcon {
  /* position: absolute; */
  /* right: 12px; */
  /* top: 0px; */
  /* color: #ddd; */
  /* z-index: 10; */
}

/* Renders a multiplication (close) sign */
/* .deleteEntryIcon:after {
  display: inline-block;
  font-weight: 300;
  font-size: 2rem;
  content: "\00d7";
} */

.deleteEntryIcon:hover {
  color: red;
  cursor: pointer;
}

.pickTeamPickGroup {
  min-width: 270px;
}

.pickTeamNameModal {

}

.pickTeamNameModal .modal-header {
  background: linear-gradient(to right, #305875, #34495E);
  color: #ffffff;
  letter-spacing: 1px;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.pickTeamNameModal .close {
  color: #ffffff;
}

.pickTeamNameModal_input {
  width: 100%;
  height: 2.5rem;
  font-size: 1.1rem;
  outline: 1px solid green;
}

.pickTeamOptionsDropdown.dropdown-menu.show {
  left: unset !important; /* Need to use important to override dropdown menu's inline styles */
  right: -35px;
}

.pickTeamPromoImageContainerMobile {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: calc(187.0 / 418.0 * 100%);
  overflow: hidden;
}

.pickTeamPromoImageContainerDesktop {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: calc(280.0 / 1460.0 * 100%);
  overflow: hidden;
}

.pickTeamPromoImageAspect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.teamSlotGolfer_meta > span:before {
    content: "•";
    display: inline-block;
    width: 1em;
    margin-left: 6px;
}

.teamSlotGolfer_meta > span:last-child:before {
    content: "";
    display: inline-block;
    width: auto;
    margin-left: 0px;
}

.availableGolfer_meta > span:before {
    content: "•";
    display: inline-block;
    width: 1em;
    margin-left: 6px;
}


.fa-duotone, .fad {
    position: relative;
    font-family: "Font Awesome 6 Duotone";
    font-weight: 900;
    letter-spacing: normal;
}

.fa-hand-holding-dollar:before{content:"\f4c0"}
.fa-hand-holding-dollar:after{content:"\f4c0\f4c0"}


.nextBtnText {
  margin-top: 6px;
  font-size: 14px;
  margin-right: 2px;
}

.backBtnText {
  margin-top: 6px;
  font-size: 14px;
  margin-left: 2px;
}
/*
.no-border > div{
  border-width: 0px !important;
  border-color: white !important;
  box-shadow: 0px 0px 0px 0px !important;
}

.no-border .css-1pahdxg-control{
  border-width: 0px;
  box-shadow: 0px 0px 0px 0px !important;
}*/

/*Stop modal from scrolling*/
body.modal-open {
  position:static !important;
  overflow:visible;
  padding:0 !important;
}
