.profileWrapper {
  padding-top: 60px;
  background-color: rgba(236, 240, 241, 0.5);
  min-height: 100vh;
}

.profileTitle {
  font-family: "Roboto";
  font-size: 2rem;
}

.profileContentTitle {
  font-family: "Roboto";
  font-size: 1.5rem;
}

.profileFormRow {
  min-height: 50px;
  align-items: center;
  -webkit-box-align: center;
}

.profileFormRow_label {
  font-family: "Roboto";
  font-size: 0.9rem;
  white-space: nowrap;
}

.profileFormRow_content {
  max-width: 350px;
}

.profileFormRow_input {
}

.profileFormRow_border {
  border-color: #ced4da !important;
}

.profileLegal {
  font-family: "Roboto";
}

.pencil-edit{
  color: rgb(0, 123, 255);
  font-size: 20px;
  border-bottom: 2px solid;
  padding-bottom: 1px;
  cursor: pointer;
}
