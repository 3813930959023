.creditBalanceRow {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.creditBalanceRow button {
  border-color: rgb(96, 174, 227);
  background-color: white;
  color: rgb(75, 165, 223);
  min-width: 50px;
  margin-left: 10px !important;
  border-radius: 0.2rem !important;
}

.creditBalanceRow button:hover {
  background-color: rgb(200, 220, 250);
  color: teal;
}

.creditBalanceRow button.active,
.creditBalanceRow button:focus {
  border-color: orange !important;
  border-width: 2px !important;
  background-color: white !important;
  color: rgb(75, 165, 223) !important;
  font-weight: 600;
}

.creditBalanceRow input {
    border-color: rgb(96, 174, 227);
    background-color: white;
    color: rgb(75, 165, 223);
    border-radius: 0.2rem;
    width: 70px;
    margin-left: 10px !important;
    text-align: center;
}

.creditBalanceRow input.active {
  border-color: orange !important;
  border-width: 2px !important;
}

.creditBalanceRow input::placeholder {
    font-size: .875rem;
    line-height: 1.5;
}
