.joinContainer {
}

.linkComponent {
  cursor: pointer;
  color: #007bff;
}

.linkComponent:hover {
  text-decoration: underline;
  /* color: #00008b; TODO this is incorrect, and this value does not appear to be standard across OS/browser */
}

.joinContent {
  padding: 1rem 1rem;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

.joinButton {
  text-transform: uppercase;
  color: #e8e8e8;
  background-color: #666;
  height: 3em;
}

.joinContent_title {
  text-transform: uppercase;
  font-family: "Roboto";
  font-size: 13px;
  line-height: 35px;
  letter-spacing: 0.3px;
  color: #94a1ab;
}

.joinContent_leaguename {
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  letter-spacing: -0.3px;
  color: #232323;
  margin-bottom: 1.5rem;
}

.joinContent_invitedby {
  font-size: 16px;
  line-height: 20px;
  color: #5b6c77;
  margin-bottom: 2rem;
}

.joinContent input {
  border: none;
}
.joinContent input:focus {
  border: none;
  outline: none;
}

.joinContent_signuplabel {
  width: 100%;
  line-height: 40px;
}

input.joinContent_signupinput {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid rgba(148, 161, 171, 0.5);
  font-size: 20px;
}

input.joinContent_signupinput:focus {
  border-bottom: 2px solid #1e88e5;
}


.formContainer {
}

.formContent_row {
  background-color: #ffffff;
  border: .5px;
  border-style: solid;
  border-color: darkgray;
  text-align: center;
}


.formContent_row_no_top_border{
  border-top: 0px;
}

.formContent_row_no_bottom_border{
  border-bottom: 0px;
}

.formContent_row_no_left_border{
  border-left: 0px;
}

.formContent_row_no_right_border{
  border-right: 0px;
}

.formContent_row_bottom_round {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.formContent_row_top_round {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.formContent_row_top_left_round {
  border-top-left-radius: 4px;
}

.formContent_row_top_right_round {
  border-top-right-radius: 4px;
}

.formContent_row_bottom_left_round {
  border-bottom-left-radius: 4px;
}

.formContent_row_bottom_right_round {
  border-bottom-right-radius: 4px;
}

.formContent_formInput_label {
  width: 100%;
  line-height: 10px;
  text-align: left;
  padding-left: .75rem;
  border-bottom: 1px;
  border-bottom-style: inset;
  padding-top: .75rem;
  padding-bottom: .75rem;
  margin-bottom: 0px;
  font-size: 10px;
  font-weight: 600;
}

input.formContent_formInput {
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 15px;
  background-color: transparent;
  width: 100%;
  border: 0;
  outline: none;
}

input.formContent_formInput:focus {
  border: 0 !important;
  outline: none;
}

.linkComponent {
  cursor: pointer;
  color: #007bff;
}

.linkComponent:hover {
  text-decoration: underline;
  /* color: #00008b; TODO this is incorrect, and this value does not appear to be standard across OS/browser */
}
