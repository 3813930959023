.eventsWrapper {
  padding-top: 60px;
  padding-bottom: 2rem;
  background-color: rgba(236, 240, 241, 0.5);
  min-height: 100vh;
}

.eventsHeaderContainer {
  width: 100%;
  height: 42px;
  background-color: #2c3e50;
  display: inline-block;
  text-align: center;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.eventsHeader {
  display: flex;
  overflow-x: auto;
  -webkit-flex-direction: row;
  flex-direction: row;
  margin-bottom: -20px;
  padding-bottom: 20px;
  padding-left: 0px;
}

.eventsHeader_item {
  display: inline-block;
  height: 42px;
  max-width: 12rem;
  text-align: left;
  color: lightgray;
  padding-top: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  line-height: 1;
  cursor: pointer;
  text-decoration: none !important;
  white-space: nowrap;
  -webkit-user-drag: none;
}

.eventsHeader a:last-child {
}

.eventsHeader_item__active {
  border-bottom: 3px solid white;
}
.eventsHeader_item:hover .eventsHeader_itemcell {
  color: #ffffff;
}

.eventsHeader_itemcell {
  font-size: 0.8rem;
  /*color: #94a9be;*/
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.3px;
  text-align: center;
  text-decoration: none;
  list-style: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.eventsHeader_item.eventsHeader_item__active .eventsHeader_itemcell {
  /*color: #ffffff;*/
}

.eventsHeader_itembadge {
}

.eventsContainer {
  font-size: 0.9rem;
  padding-top: 1rem;
}
