
/*
 WORKAROUND FOR MODAL HANDLING in iOS 11:
 See: https://hackernoon.com/how-to-fix-the-ios-11-input-element-in-fixed-modals-bug-aaf66c7ba3f8
 Also: https://stackoverflow.com/questions/46339063/ios-11-safari-bootstrap-modal-text-area-outside-of-cursor
*/
body.modal-open {
  position: fixed;
  width: 100%;
}

.mcModalHeader {
  background: linear-gradient(to right, #305875, #34495E);
  color: #ffffff;
  letter-spacing: 1px;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.mcModalHeader .close {
  color: #ffffff;
}


.table-head-fixed tbody{
  display:block;
  overflow:auto;
  height:200px;
  width:100%;
  border-left: 1px solid #dee2e6;
}
.table-head-fixed thead tr{
  display:block;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.table-head-fixed thead tr th{
  border-bottom-width:1px;
}

.loading{
  top: 50%;
}

.loading .modal-content{
  background: transparent;
  border: none;
  color: white;
}
