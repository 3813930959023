.fieldModalHeader {
  background-color: #383838;
  text-transform: uppercase;
  color: #e8e8e8;
  font-family: Roboto;
  font-size: 0.8em;
}

.fieldModalHeader .subtitle {
  color: #aaa;
  font-size: 0.6em;
  font-weight: bolder;
}

.payoutsHeader {
  background-color: #f6f6f6;
  color: #aaa;
  text-transform: uppercase;
  font-size: 0.7rem;
}

.adminWrapper {
  padding-top: 60px;
  background-color: rgba(236, 240, 241, 0.5);
  min-height: 100vh;
}

.adminTitle {
  font-family: Roboto;
  font-size: 2rem;
}

.adminContentTitle {
  font-family: Roboto;
  font-size: 1.5rem;
}

.createClubForm {
  font-family: "Roboto";
}
.createClubForm .form-control {
  font-family: "Roboto";
  font-weight: 500;
  /*color: #28a745;*/
}

.createClubForm .form-control::placeholder {
  font-family: "Roboto";
  font-weight: 500;
}

.createClubForm .contentTitle {
  font-size: 1.1rem;
}



.my_custom_off {
  color: #fff;
  background-color: rgb(41, 182, 246);
  border-color: rgb(2, 136, 209);
  color: white;
  transition: 0.5s;
}
.my_custom_off:hover {
  transition: 0.5s;
  color: white;
  background-color: rgb(2, 136, 209);
  border-color: #01579b;
}

.leaderboardRowPromoBannerDesktop{
    height:70px;
    width: 100%; /* fall back */
    background-position:center center;
    /*background-image:url(https://media.majorschallenge.com/leaderboardAdvBanner/regularLeagueDesktop.png);*/
    background-repeat:no-repeat;
    -webkit-background-size:contain;
    -moz-background-size:contain;
    -o-background-size:contain;
    background-size:contain;
}

.leaderboardRowPromoBannerMobile{
    height:70px;
    width: 100%; /* fall back */
    background-position:center center;
    /*background-image:url(https://media.majorschallenge.com/leaderboardAdvBanner/regularLeagueMobile.png);*/
    background-repeat:no-repeat;
    -webkit-background-size:contain;
    -moz-background-size:contain;
    -o-background-size:contain;
    background-size:contain;
}

.fieldCompareTable td{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

}

.sportradarlogo {
    height: 25px;
    width: 70px;
    background: url('https://www.sportradar.com/choose_region/sportradar-logo.svg') no-repeat 0px 10px/60px;
}

.mclogo {
    height: 25px;
    width: 70px;
    background: url('/static/media/brand.59ecf122.png') no-repeat 0px 10px/50px;
}

.tournamentSeason_dropdown  .dropdown-menu {
  min-width: 2rem;
}
