.feedbackRenderComponent:hover {
  cursor: pointer;
}

.feedbackTitle {
  text-transform: capitalize;
  color: #555;
  font-size: 0.85em;
}

.typeButton {
  min-width: 30%;
}

.typeTitle {
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: bolder;
}

.typeDesc {
  font-weight: 100;
  font-size: 0.7em;
}
