@import url(https://fonts.googleapis.com/css?family=Archivo:700|Roboto|Roboto+Condensed|Vollkorn:400|Vollkorn:700|Roboto:500|Roboto:900|Roboto:300);
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-overflow-scrolling: touch;
}

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-drag: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}


.or-divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #666;
  font-weight: bold;
  font-size: 1rem;
  margin: 1rem 0;
}

.or-divider::before,
.or-divider::after {
  content: '';
  flex: 1 1;
  border-bottom: 1px solid #ccc;
  margin: 0 10px;
}

.btn-light-red {
  color: #fff !important;
  background-color: #dc816e !important;
  border-color: #dc816e !important;
}

.btn-light-red:hover{
  background-color: #d06650 !important;
}

.btn-light-red-outline {
  color: #dc816e !important;
}

.card-header{
  border-left: 8px solid green;
}

.vertical-text {
   -webkit-writing-mode: vertical-rl;
       -ms-writing-mode: tb-rl;
           writing-mode: vertical-rl; /* Vertical text, left-to-right */
   -webkit-text-orientation: mixed;
           text-orientation: mixed;
   background-color: green;
   color: white;
   display: inline;
   display: initial;
   font-size: .7em;
 }

.vertical-text-td
 {
    background-color: green;
    color: white;
    text-align: center;
    vertical-align: middle;

}


.no-border-top{
  border-top: 0px !important;
}

.light-border-t{
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.light-border-b{
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.light-border-lr{
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.light-border-tb{
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.facebookAuthLoginButton {
  color: white;
  background-color: #3b5998;
  border-radius: 3px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 180px;
}

.facebookAuthLoginButton:hover {
  cursor: pointer;
  background-color: #31497d;
}

.facebookAuthLoginIcon {
  font-size: 1.5rem;
  margin-right: 0.25rem;
}

.facebookAuthLoginIconText {
  font-size: 0.8rem;
  vertical-align: text-bottom;
  font-family: Roboto;
}

.googleAuthLoginButton {
  color: white;
  background-color: #4285F4;
  font-size: 1.2rem;
  border-radius: 3px;
  text-align: center;
  width: 180px;
}

.googleAuthLoginButton:hover {
  cursor: pointer;
  background-color: #366ad3;
}

.googleAuthLoginButtonImgContainer {
  background-color: #fff;
  border-radius: 2px;
  width: 2.0rem;
  height: 1.8rem;
  margin-right: 0.2rem;
  padding: 2px;
}

.googleAuthLoginButtonImg {
  height: 1.5rem;
  margin-bottom: 0.35rem;
}

.googleAuthLoginIconText {
  font-size: 0.8rem;
  vertical-align: text-bottom;
  font-family: Roboto;
  margin-top: 5px;
}

.react-confirm-alert-body > h1 {
    margin-top: 0;
    font-size: 20px;
    font-weight: bold;
}

.text-responsive {
  font-size: calc(5% + 1vw + 1vh);
}

.react-confirm-alert-overlay{
  z-index: 9999;
}



.react-confirm-alert-button-group>button:first-child{
  color: black ;
  background-color: #e8e8e8 ;
}

.react-confirm-alert-button-group button.button_primary{
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}



.react-confirm-alert-button-group button.btn1 {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-confirm-alert-button-group button.button_success{
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.react-confirm-alert-button-group button.btn_outline_success {
    color: #28a745;
    background-color: transparent;
    border: 1px solid transparent;
    border-color: #28a745;
}


/*
 WORKAROUND FOR MODAL HANDLING in iOS 11:
 See: https://hackernoon.com/how-to-fix-the-ios-11-input-element-in-fixed-modals-bug-aaf66c7ba3f8
 Also: https://stackoverflow.com/questions/46339063/ios-11-safari-bootstrap-modal-text-area-outside-of-cursor
*/
body.modal-open {
  position: fixed;
  width: 100%;
}

.mcModalHeader {
  background: -webkit-gradient(linear, left top, right top, from(#305875), to(#34495E));
  background: -webkit-linear-gradient(left, #305875, #34495E);
  background: linear-gradient(to right, #305875, #34495E);
  color: #ffffff;
  letter-spacing: 1px;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.mcModalHeader .close {
  color: #ffffff;
}


.table-head-fixed tbody{
  display:block;
  overflow:auto;
  height:200px;
  width:100%;
  border-left: 1px solid #dee2e6;
}
.table-head-fixed thead tr{
  display:block;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.table-head-fixed thead tr th{
  border-bottom-width:1px;
}

.loading{
  top: 50%;
}

.loading .modal-content{
  background: transparent;
  border: none;
  color: white;
}

.loginModal form label {
  width: 100%;
}

.loginModal form label input {
  width: inherit;
}

.btn.btn-outline-success.loginButton {
  border-color: #90a4ae;
  color: #b0bec5;
}

.btn.btn-outline-success.loginButton:hover {
  background-color: #90a4ae;
  color: #ffffff;
}

.btn.btn-outline-success.loginButtonActivation {
  padding-left: 50px;
  padding-right: 50px;
}

.btn.btn-outline-success.loginButtonActivation:hover {

}

.landingHeader {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(52,73,94,0.9)), to(rgba(52,73,94,0.9))), url(/static/media/golfcourse.d206a36e.jpg);
  background-image: -webkit-linear-gradient(top, rgba(52,73,94,0.9), rgba(52,73,94,0.9)), url(/static/media/golfcourse.d206a36e.jpg);
  background-image: linear-gradient(to bottom, rgba(52,73,94,0.9), rgba(52,73,94,0.9)), url(/static/media/golfcourse.d206a36e.jpg);
  background-repeat: no-repeat;
  background-position: top, top -50px center;
  background-size: auto;
  color: #ffffff;
  height: 60px;
  z-index: 10;
}

.landingHeaderTitle {
  font-family: 'Archivo', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 0.86;
  text-align: left;
  margin-bottom: 0px;
}

.landingHeaderTitle_majors {
  color: #2ecc71;
}

.landingHeaderTitle_challenge {
  color: #ffffff;
}

.landingHeader.landingHeaderTransparent {
  background: none !important;
  background-color: rgba(0,0,0,0);
}

.landingHeaderLink {
  font-family: "Roboto";
  font-size: 15px;
  line-height: 16px;
  color: #b0bec5;
  white-space: nowrap;
}

.landingHeaderLink:hover {
  color: #ffffff;
  text-decoration: none;
}

.landingHeaderSignup {
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  background-color: #21cc71;
}

.landingHeaderSignup:hover {
  background-color: #28a745;
}

.landingHeader a {
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  letter-spacing: 0.6px;
  color: #94a9be !important;
  text-transform: uppercase;
  white-space: nowrap;
}

.landingHeader a:hover {
  color: #ffffff !important;
  text-decoration: none;
}

.landingFooter {
  height: 200px;
  background-color: #2c3e50;
  color: #ffffff;
  align-items: start;
  padding-top: 2rem;
}

.landingFooterMobile {
  height: 60px;
  background-color: #2c3e50;
  color: #ffffff;
}

@media (max-width: 991.98px) {
  .landingFooter .container {
    width: 100%;
    max-width: none;
  }
}

.landingFooter_heading {
  font-family: 'Roboto';
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #ffffff !important;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 30px;
}

.landingFooter_link {
  font-family: 'Roboto';
  color: #90a4ae;
  white-space: nowrap;
  line-height: 30px;
}

.landingFooter_link:hover {
  color: #ffffff;
  text-decoration: none;
}

.landingFooter .loginLink {
  color: #90a4ae;
}
.landingFooter .loginLink:hover {
  color: #ffffff;
  text-decoration: none;
}


.landingFooter_copyright {
  color: #90a4ae;
  font-family: 'Roboto';
  font-size: 14px;
  letter-spacing: 0.3px;
}

.mcPubContainer {
  background-color: #ecf0f1;
}

#root {
  min-height: 100vh;
}

.onboardingBg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  min-height: 920px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(52,73,94,0.9)), to(rgba(52,73,94,0.9))), url(/static/media/golfcourse.d206a36e.jpg);
  background-image: -webkit-linear-gradient(top, rgba(52,73,94,0.9), rgba(52,73,94,0.9)), url(/static/media/golfcourse.d206a36e.jpg);
  background-image: linear-gradient(to bottom, rgba(52,73,94,0.9), rgba(52,73,94,0.9)), url(/static/media/golfcourse.d206a36e.jpg);
  background-repeat: no-repeat;
  background-position: top, top -50px center;
  background-size: auto;
  z-index: 0;
}

.landingCard {
  position: relative;
  padding: 1rem 1rem;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.3);
  z-index: 10;
  color: #212121;
}

.landingHeroBg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 728px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(52,73,94,0.9)), to(rgba(52,73,94,0.9))), url(/static/media/golfcourse.d206a36e.jpg);
  background-image: -webkit-linear-gradient(top, rgba(52,73,94,0.9), rgba(52,73,94,0.9)), url(/static/media/golfcourse.d206a36e.jpg);
  background-image: linear-gradient(to bottom, rgba(52,73,94,0.9), rgba(52,73,94,0.9)), url(/static/media/golfcourse.d206a36e.jpg);
  background-repeat: no-repeat;
  background-position: top, top -50px center;
  background-size: auto;
  z-index: 0;
}

.landingHeroBg.promo {
  height: 508px;
}

.landingHero {
  position: relative;
  height: calc(728px - 60px - 2rem);
  padding-top: 2rem;
}

.landingHero.promo {
  height: calc(508px - 60px - 2rem);
}

.landingHero_title {
  font-family: "Vollkorn";
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  color: #ffffff;
  white-space: nowrap;
}

.landingHero_title.club {
  font-size: 42px;
  line-height: 52px;
  font-weight: 400;
  white-space: normal;
}

.landingHero_subtitle {
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
  color: #b0bec5;
}

.landingHero_details {
  font-family: "Roboto";
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
  color: #b0bec5;
}

.landingHero.container {
  padding-left: 0px;
}

.landingHero_laptop {
  position: relative;
}

.landingHero_laptop__img {
  width: 100%;
}

.landingHero_laptop__img.promo {
  width: 80%;
}

.landingHeroSignup {
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: 500;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 17rem;
  background-color: #21cc71;
}

.landingHeroSignup:hover {
  background-color: #28a745;
}


.landingHeroSignup__alt {
  font-size: 0.9rem;
  font-family: "Roboto";
  font-weight: 500;
  border: none;
  background-color: #ffffff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  /* margin-top: rem; */
  margin-bottom: 2rem;
  width: 17rem;
}

.landingContainer {
  padding-left: 0px;
  padding-top: 2rem;
}

.landingSection1 {
  background-color: #ecf0f1;
  position: relative;
  width: 100%;
}


.landingSectionTitle__dark {
  font-family: "Vollkorn";
  font-size: 30px;
  font-weight: 700;
  line-height: 2.4;
  color: #212121;
}

.landingSectionTitle__white {
  font-family: "Vollkorn";
  font-weight: 700;
  font-size: 30px;
  line-height: 2.4;
  color: #ffffff;
}

.landingBD_image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.landingBD_title {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 18px;
  color: #212121;
  line-height: 22px;
  text-align: center;
}

.landingBD_text {
  font-family: "Roboto";
  font-size: 14px;
  color: #78909c;
  line-height: 22px;
  text-align: center;
}

.landingSection2 {
  background-color: #3498db;
}

.landingSection3 {
  background-color: #21cc71;
}

.landingSection4 {
  background-color: #ffffff;
}

.landingHero_phone {
  position: relative;
}

.landingHero_phone__img {
  width: 100%;
}

.landingSubtitle__white {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.landingText__white {
  font-family: "Roboto";
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
}

.landingText__white a {
  color: #ffffff;
  text-decoration: underline;
}

.landingStepNumberCircle {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Roboto";
  display: inline-block;
  height: 2em;
  width: 2em;
  line-height: 2em;
  border-radius: 1em;

  background-color: #ffffff;
  border-color: #ffffff;
  color: #3498db;

  border: 1px solid #3498db;
  text-align: center;
  position: relative;
  top: -0.25em;
}

.landingSection_smalltitle {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: bold;
  line-height: 36px;
  letter-spacing: 0.6px;
  color: #90a4ae;
  text-transform: uppercase;
}

.landingSection_ctatitle {
  font-size: 32px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 1px;
  color: #ffffff;
}

.landingSection_ctabutton {
  font-size: 0.9rem;
  font-family: "Roboto";
  font-weight: 500;
  border: none;
  background-color: #ffffff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 2rem;
  width: 17rem;
}

.clubHeader_ctabutton {
  font-size: 0.9rem;
  font-family: "Roboto";
  font-weight: 500;
  border: none;
  background-color: #ffffff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-transform: uppercase;
  color: #4e6bf5;
}

.clubHeader_ctabutton:hover {
  background-color: #78909c;
}



.landingJumboQuotes {
  position: relative;
  font-size: 44px;
  color: #cfd8dc;
  top: -20px;
}

.landingQuote {
  font-family: "Vollkorn";
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.38;
  color: #78909c;
}

.landingPromoEvent_name {
  font-size: 2rem;
  font-family: "Roboto";
  font-weight: 300;
  color: #2c3e50;
  padding-bottom: 2rem;
}

.landingPromoEvent_cell {
  text-align: center;
  white-space: nowrap;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.landingPromoEvent_250K {
  text-align: center;
  font-weight: 800;
  white-space: nowrap;
  padding-bottom: 0.5rem;
}


.landingPromoEvent_celldata {
  font-size: 1.15rem;
  font-family: "Roboto";
  font-weight: 300;
  color: #2c3e50;
}

.landingPromoEvent_celllabel {
  font-size: 0.85rem;
  font-family: "Roboto";
  font-weight: 500;
  color: #78909c;
  text-transform: uppercase;
}

.landingPromoEvent_cta {
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.8rem 3rem;
  background-color: #21cc71;
}

.landingPromoEvent_cta:hover {
  background-color: #28a745;
}

.clubLandingHeroBg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 700px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(52,73,94,0.9)), to(rgba(52,73,94,0.9))), url(/static/media/swinging2.4ce2d9d4.jpg);
  background-image: -webkit-linear-gradient(top, rgba(52,73,94,0.9), rgba(52,73,94,0.9)), url(/static/media/swinging2.4ce2d9d4.jpg);
  background-image: linear-gradient(to bottom, rgba(52,73,94,0.9), rgba(52,73,94,0.9)), url(/static/media/swinging2.4ce2d9d4.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto;
  z-index: 0;
}


.clubLandingHero {
  position: relative;
  height: calc(700px - 60px - 2rem);
}

.clubLandingHeroForm {
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 2px 18px 0 rgba(31, 43, 47, 0.5);
  background-color: #ffffff;
}

.clubIntakeForm {

}

.clubIntakeForm_title {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 24px;
  color: #212121;
  line-height: 22px;
  text-align: center;
}

.clubIntakeForm_subtitle {
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #78909c;
}

.clubIntakeForm .form-control {
  width: 100%;
  border: 2px solid #eceff1;
}

.clubIntakeForm .form-control::-webkit-input-placeholder {
  font-family: "Roboto";
  color: #90a4ae;
}

.clubIntakeForm .form-control::-moz-placeholder {
  font-family: "Roboto";
  color: #90a4ae;
}

.clubIntakeForm .form-control:-ms-input-placeholder {
  font-family: "Roboto";
  color: #90a4ae;
}

.clubIntakeForm .form-control::-ms-input-placeholder {
  font-family: "Roboto";
  color: #90a4ae;
}

.clubIntakeForm .form-control::placeholder {
  font-family: "Roboto";
  color: #90a4ae;
}

.clubIntakeForm_submit {
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: 500;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  width: 100%;
  background-color: #2ecc71;
  text-transform: uppercase;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  border: none;
}

.clubIntakeForm_submit:hover:enabled {
  background-color: #28a745;
}


@media (max-width: 575px) {
  .landingHero.container {
    padding-left: 15px;
  }
  .landingContainer {
    padding-left: 15px;
  }

}

@media (max-width: 767px) {
  .landingHero_title.club {
    font-size: 28px;
    line-height: 36px;
  }
  .landingJumboQuotes {
    top: -10px;
  }
  .landingHero_subtitle{
    font-size: .87rem;
  }
}

@media (max-width: 1199px) {
  .landingHero_subtitle{
    font-size: .85rem;
  }

}

.promoBold {
  font-weight: bold;
  font-size: 22px;
}

.pgaLogo{
  height: 70px;
  margin-left: -9px;
}

.jdmeLogo{
  height: 65px;
  margin-left: -9px;
}

.joinContainer {
}

.linkComponent {
  cursor: pointer;
  color: #007bff;
}

.linkComponent:hover {
  text-decoration: underline;
  /* color: #00008b; TODO this is incorrect, and this value does not appear to be standard across OS/browser */
}

.joinContent {
  padding: 1rem 1rem;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

.joinButton {
  text-transform: uppercase;
  color: #e8e8e8;
  background-color: #666;
  height: 3em;
}

.joinContent_title {
  text-transform: uppercase;
  font-family: "Roboto";
  font-size: 13px;
  line-height: 35px;
  letter-spacing: 0.3px;
  color: #94a1ab;
}

.joinContent_leaguename {
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  letter-spacing: -0.3px;
  color: #232323;
  margin-bottom: 1.5rem;
}

.joinContent_invitedby {
  font-size: 16px;
  line-height: 20px;
  color: #5b6c77;
  margin-bottom: 2rem;
}

.joinContent input {
  border: none;
}
.joinContent input:focus {
  border: none;
  outline: none;
}

.joinContent_signuplabel {
  width: 100%;
  line-height: 40px;
}

input.joinContent_signupinput {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid rgba(148, 161, 171, 0.5);
  font-size: 20px;
}

input.joinContent_signupinput:focus {
  border-bottom: 2px solid #1e88e5;
}


.formContainer {
}

.formContent_row {
  background-color: #ffffff;
  border: .5px;
  border-style: solid;
  border-color: darkgray;
  text-align: center;
}


.formContent_row_no_top_border{
  border-top: 0px;
}

.formContent_row_no_bottom_border{
  border-bottom: 0px;
}

.formContent_row_no_left_border{
  border-left: 0px;
}

.formContent_row_no_right_border{
  border-right: 0px;
}

.formContent_row_bottom_round {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.formContent_row_top_round {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.formContent_row_top_left_round {
  border-top-left-radius: 4px;
}

.formContent_row_top_right_round {
  border-top-right-radius: 4px;
}

.formContent_row_bottom_left_round {
  border-bottom-left-radius: 4px;
}

.formContent_row_bottom_right_round {
  border-bottom-right-radius: 4px;
}

.formContent_formInput_label {
  width: 100%;
  line-height: 10px;
  text-align: left;
  padding-left: .75rem;
  border-bottom: 1px;
  border-bottom-style: inset;
  padding-top: .75rem;
  padding-bottom: .75rem;
  margin-bottom: 0px;
  font-size: 10px;
  font-weight: 600;
}

input.formContent_formInput {
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 15px;
  background-color: transparent;
  width: 100%;
  border: 0;
  outline: none;
}

input.formContent_formInput:focus {
  border: 0 !important;
  outline: none;
}

.linkComponent {
  cursor: pointer;
  color: #007bff;
}

.linkComponent:hover {
  text-decoration: underline;
  /* color: #00008b; TODO this is incorrect, and this value does not appear to be standard across OS/browser */
}

.signupForm {

}

.signupFormLabel {
  width: 100%;
  text-align: left;
}

.signupFormInput {
  display: block;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .golfer-headshot-container-big-holder{
    width: 98% !important;
  }

  .golfer-headshot-container-big{
    height: 70px !important;
    width: 70px !important;
  }

  .golfer-headshot-big{
    height: 55px !important;
    width: 55px !important;
  }

  .golfer-headshot-alt{
    margin-left: 42px !important;
    margin-top: -14px !important;
  }
}

.leaguePromoImageContainer {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: calc(222.0 / 600.0 * 100%);
  overflow: hidden;
}

.leaguePromoImageAspect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.golfer-headshot-container-big-holder{
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  width: 88%;
}

.golfer-headshot-container-big{
  height: 90px;
  width: 90px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5))), #CFD4D9;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%), #CFD4D9;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%), #CFD4D9;
  border: 3px solid #FFFFFF;
  border-radius: 50px;
  padding: 4px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.golfer-headshot-big{
  height: 75px;
  width: 75px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  color: transparent;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
}

.golfer-headshot-mid{
  height: 55px;
  width: 55px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  color: transparent;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
}

.golfer-headshot-small{
  height: 40px;
  width: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  color: transparent;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
}

.roster-headshots-name{
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #272A2E;
  margin: 8px 0px;
  white-space: nowrap;
  cursor: pointer;
}

.golfer-name-initial{
  color: #38485C;
  font-size: 30px;
}

.golfer-name-initial div{
  margin-left: auto;
  margin-right: auto;
}

.golfer-headshot-alt{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /*padding: 10px 5px;*/


  width: 32px;
  height: 14px;
  margin-top: -17px;
  margin-left: 58px;

  background: #38485C;
  border-radius: 6px;
  color: #FFFFFF;
  /*border: 2px dashed #38485C;
  box-sizing: border-box;*/
}

.alt{
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 9px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 5px;
}


.table.contestLBDetailsTable {
  margin: 0;
  background-color: rgba(52, 152, 219, 0.13);
}

.contestLBDetailsHeader {
  color: #a1a1a1;
  font-size: 0.8rem;
}

.rulesContent {
}

.rulesContent .row {
  padding-bottom: 1rem;
}

.rulesContent h5 {
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 900;
  line-height: 22px;
  color: #212121;
}

.rulesContent h6 {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: bold;
  line-height: 22px;
  color: #212121;
}

.rulesContent p, .rulesContent li {
  font-size: 14px;
  font-family: "Roboto";
  line-height: 22px;
  color: #78909c;
}

.rulesContentTitle {
  font-family: "Vollkorn", serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.ruleStepNumberCircle {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Roboto";
  display: inline-block;
  height: 2em;
  width: 2em;
  line-height: 2em;
  border-radius: 1em;

  background-color: #2ecc71;
  border-color: #2ecc71;
  color: #ffffff;

  border: 1px solid #d9dde0;
  text-align: center;
  position: relative;
  top: -0.25em;
}

.richTextEditor p{
  line-height: normal !important;
}

.richTextEditor p span{
  margin-bottom: 3px;
}

.foundationContent p, .foundationContent li {
  font-size: 14px;
  font-family: "Roboto";
  line-height: 22px;
}

.foundationContent li {
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .ruleStepNumberCircle {
    left: -0.5em;
  }
}


.completedEntryCollapse {
}

.table.completedEntriesTable {
  margin: 0;
  background-color: rgba(52, 152, 219, 0.13);
}

.completedEntriesTableHeader {
  color: #a1a1a1;
  font-size: 0.8rem;
}

.completedEntryRow {

}

@media (min-width: 992px) {
  .dashboard .col-lg-auto {
    min-width: 700px;
  }
}

@media (min-width: 768px){
.col-md-8 {
    flex: 0 0 95% !important;
    max-width: 95% !important;
  }
}

@media (min-width: 770px){
.col-md-8 {
    flex: 0 0 66.666667% !important;
    max-width: 66.666667% !important;
  }
}



.dashboard {
  font-size: .85em;
}

.dashboardPromoImageContainer {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: calc(138.0 / 414.0 * 100%);
  overflow: hidden;
}

.dashboardPromoImageAspect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.feedbackRenderComponent:hover {
  cursor: pointer;
}

.feedbackTitle {
  text-transform: capitalize;
  color: #555;
  font-size: 0.85em;
}

.typeButton {
  min-width: 30%;
}

.typeTitle {
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: bolder;
}

.typeDesc {
  font-weight: 100;
  font-size: 0.7em;
}

.profileWrapper {
  padding-top: 60px;
  background-color: rgba(236, 240, 241, 0.5);
  min-height: 100vh;
}

.profileTitle {
  font-family: "Roboto";
  font-size: 2rem;
}

.profileContentTitle {
  font-family: "Roboto";
  font-size: 1.5rem;
}

.profileFormRow {
  min-height: 50px;
  align-items: center;
  -webkit-box-align: center;
}

.profileFormRow_label {
  font-family: "Roboto";
  font-size: 0.9rem;
  white-space: nowrap;
}

.profileFormRow_content {
  max-width: 350px;
}

.profileFormRow_input {
}

.profileFormRow_border {
  border-color: #ced4da !important;
}

.profileLegal {
  font-family: "Roboto";
}

.pencil-edit{
  color: rgb(0, 123, 255);
  font-size: 20px;
  border-bottom: 2px solid;
  padding-bottom: 1px;
  cursor: pointer;
}

.inviteModal form div div {
  width: 100%;
}

.inviteModal form div div input {
  width: inherit;
}

.inviteModal .valid .email {
  border-color: lightgreen;
}

.inviteModal .invalid .email,
.inviteModal .current .email {
  border-color: red;
}

.inviteModal .feedback {
    color: red;
    font-size: .85rem;
}

.inviteModal form div div textarea {
  width: inherit;
}

.multiEmails{
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto;
}
.inviteModal .addInvitee {
    color: #0af;
    cursor: pointer;
}


.react-multi-email.empty > span[data-placeholder] {
    display: contents !important;
    color: #ccc;
}

.react-multi-email > input {
  width: 100% !important;
}


.validBar{
  background-color:green;
  margin-right:0.4em;
  display:inline-block;
}

.invalidBar{
  background-color:red;
  margin-right:0.4em;
  display:inline-block;
}

.invitedBar{
  background-color:firebrick;
  margin-right:0.4em;
  display:inline-block;
}

.currentBar{
  background-color:coral;
  margin-right:0.4em;
  display:inline-block;
}

.legend{
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
}


.react-multi-email > input {
  max-width:300px !important;
}

@media (max-width: 991px) {
   .leaderboardContainer {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      min-width: 100%;
   }
}

.leaderboardContainer {
  max-width: 1200px;
}

.mcLeaderboardHeaderTitle {
  padding-left: 0.75rem;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 500;
  color: #232323;
  white-space: nowrap;
}

.mcLeaderboardHeaderSubtitle {
  padding-left: 0.75rem;
  color: #5b6c77;
}


.mcLeaderboardHeaderWinnings {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  color: #5b6c77;
  text-align: left;
  white-space: nowrap;
}

.mcLeaderboardHeaderPointpool {
  font-family: "Roboto";
  font-size: 14px;
  color: #5b6c77;
  text-align: left;
  white-space: nowrap;
}

.mcLeaderboardHeaderPointpoolValue {
  font-weight: 500;
  color: #232323;
}

.mcLeaderboardTable > thead > tr > th {
  font-family: "Roboto";
  font-size: 0.9em;
  border-top-color: #ecf0f1;
  border-bottom: none;
  background-color: #ecf0f1;
  color: #5b6c77;
  text-transform: uppercase;
}

.mcLeaderboardTable > thead > tr > td {
  font-family: "Roboto";
  border-top-color: #ecf0f1;
  border-bottom: none;
}

.mcLeaderboardTeamRowBody.friend,
.mcLeaderboardTeamRowBody.expanded {
  background-color: rgba(52, 152, 219, 0.2);
}

.mcLeaderboardTeamRowBody.personal,
.mcLeaderboardTeamRowBody.expanded.friend {
  background-color: rgba(52, 152, 219, 0.4);
}

.mcLeaderboardTeamRowBody.host,
.mcLeaderboardTeamRowBody.expanded.host {
  background-color: #EEDC82;
}

.mcLeaderboardTeamRowRank.winner {
  font-size: 0.9em;
  /* font-weight: bold; */
  display: inline-block;
  height: 2em;
  width: 2em;
  line-height: 2em;
  border-radius: 1em;

  background-color: #2ecc71;
  border-color: #2ecc71;
  color: #ffffff;

  border: 1px solid #d9dde0;
  text-align: center;
}

.mcLeaderboardTeamRowRank:not(.winner) {
  font-size: 0.9em;
    /* font-weight: bold; */
}
/* .personal .mcLeaderboardTeamRowRank {
    color: #3498db;
    border-color: #3498db;
}

.mcLeaderboardTeamRowRank.winner {
  background-color: #2ecc71;
  border-color: #2ecc71;
  color: #ffffff;
} */

/* .personal .mcLeaderboardTeamRowRank.winner {
    background-color: #3498db;
    border-color: #3498db;
    color: #ffffff;
} */

.mcLeaderboardTeamRowTeam {
  font-family: "Roboto";
  font-weight: normal;
  color: #232323;
  white-space: nowrap;
}

.mcLeaderboardTeamRowBase {
  font-family: "Roboto";
  color: #515356;
  font-weight: normal;
  white-space: nowrap;
  vertical-align: middle!important;
}

.pgaLeaderboardTitle {
  font-family: "Roboto";
  color: #232323;
  font-weight: 500;
  font-size: 18px;
}

.pgaLeaderboardSubtitle {
  font-family: "Roboto";
  color: #5b6c77;
  font-size: 12px;
  text-align: right;
}

.pgaLeaderboardRow {
  font-size: 0.85em;
}

.pgaLeaderboardRow.personal {
  background-color: rgba(52, 152, 219, 0.2);
}

.pgaLeaderboardRow.others {
  background-color: #F0F0E0;
}

.pgaLeaderboardPlayer {
  font-family: "Roboto";
  color: #232323;
  white-space: nowrap;
}

.pgaLeaderboardPlayer:hover {
  cursor: pointer;
  color: blue;
}

.pgaLeaderboardTable > thead > tr > th,
.pgaLeaderboardTable > tbody > tr > td {
  /*border-top-color: #ecf0f1;*/
  border-bottom: none;

}

.leaderboardPromoImageContainer {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: calc(240.0 / 600.0 * 100%);
  overflow: hidden;
}

.leaderboardPromoImageAspect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.leaderboardRowPromoBannerDesktop{
    min-height:70px;
    width: 100%; /* fall back */
    background-position:center center;
    /*background-image:url(https://media.majorschallenge.com/leaderboardAdvBanner/regularLeagueDesktop.png);*/
    background-repeat:no-repeat;
    background-size:contain;
}

.leaderboardRowPromoBannerMobile{
    height:70px;
    width: 100%; /* fall back */
    background-position:center center;
    /*background-image:url(https://media.majorschallenge.com/leaderboardAdvBanner/regularLeagueMobile.png);*/
    background-repeat:no-repeat;
    background-size:contain;
}

.paginationBtn:hover{
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.renderComponent {
  cursor: pointer;
  color: #007bff;
}

.renderComponent:hover {
  text-decoration: underline;
  /* color: #00008b; TODO this is incorrect, and this value does not appear to be standard across OS/browser */
}

.rulesPromoImageContainer {
  position: relative;
  height: 0;
  width: 100%;
  max-width: 420px;
  padding-top: calc(187.0 / 418.0 * 100%);
  overflow: hidden;
}

.rulesPromoImageContainer > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.eventTitle {
  font-family: Roboto;
  font-size: 2rem;
}

.eventTitle_sub {
  color: #999;
  font-weight: 300;
  font-size: 1.5rem;
}

.eventTitle_sub_sub {
  color: #999;
  font-weight: 300;
  font-size: 1rem;
}

.contestlederboard .eventTitle {
  font-family: inherit;
  font-size: 1.4rem;
}

.contestlederboard .eventTitle_sub {
  font-size: 1.0rem;
}




@media (max-width: 575px) {
  .eventTitle {
    font-size: 1.55rem;
  }

  .eventTitle_sub {
    font-size: 1.15rem;
  }
}

.oadField .eventTitle {
  font-size: 1.3rem;
}

.oadField .eventTitle_sub {
  font-size: 1rem;

}

.oadField .eventTitle_sub_sub {
  font-size: 1rem;
}

@media screen and (max-width: 600px) {
  .d-mob-potrait {
    display: block !important;
  }


}


.oadFullScheduleDesktop{
  min-width: 700px;
}

.golferModalContainer {
  max-width: 650px;
}

.golferModal {
  min-height: 500px;
}

.golferModalInjuryHeader{
  background: lightpink;
  box-shadow: 0px 1px 1px pink;
}

.golferModalInjuryHeader_label{
  margin-left: 0.8rem;
  font-size: 0.8rem;
  font-weight: bold;
}

.golferModalInjuryHeader_value{
  margin-left: 3px;
  font-size: .8rem;
  font-weight: bold;
  color: firebrick;

}

.golferModalHeader {
  background: -webkit-gradient(linear, left top, right top, from(#305875), to(#34495E));
  background: -webkit-linear-gradient(left, #305875, #34495E);
  background: linear-gradient(to right, #305875, #34495E);
  box-shadow: 0px 1px 1px gray;
}

.golferModalHeader:hover {
  cursor: default;
}

.golferModalHeader_imgplaceholder {
  color: #ddd;
  font-size: 10rem;
  display: inline-block;
  width: 175px;
  height: 175px;
  text-align: center;
}

.golferModalHeader_content {
  margin-left: 0.5rem;
}
.golferModalHeader_name {
  color: #fff;
  margin-bottom: 0;
  margin-top: 1rem;
  -webkit-font-feature-settings: ;
          font-feature-settings: ;
  -webkit-font-kerning: ;
          font-kerning: ;
}

.golferModalHeader_subtitle {
  color: #aaa;
  font-size: 0.7rem;
}

.golferModalHeader_close {
  position: absolute;
  right: 5px;
  top: -10px;
  color: #ddd;
  z-index: 999;
  font-size: 2rem;
  font-weight: 200;
}
.golferModalHeader_close:after{
  display: inline-block;
  content: "\00d7"; /* Renders a multiplication sign */
}

.golferModalHeader_close:hover {
  cursor: pointer;
}

.golferModalHeaderDataRow {
  margin-top: 1rem;
}

.golferModalHeaderDataCell {
  line-height: 0.5;
}

.golferModalHeaderDataCellSmall {
  line-height: 0.5;
  margin-left: -0.7rem;
  margin-right: -0.7rem;
}

.golferModalHeaderDataCellLarge {
  line-height: 0.5;
  margin-right: 1.4rem;
}

.golferModalHeaderDataCell_value {
  color: #fff;
  font-size: 0.8rem;
  font-weight: normal;
  text-align: center;
  margin-bottom: 0;
}

.golferModalHeaderDataCell_label {
  color: #aaa;
  font-size: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
}

.golferModal .modal-body {
  padding: 10px;
}

.golferModalOptionRow {
  padding-bottom: 0.5rem;
}

.golferModalOptionRow button,
.golferModalOptionRow_dropdowntoggle {
  border-color: rgb(96, 174, 227);
  background-color: white;
  color: rgb(75, 165, 223);
}

.golferModalOptionRow button:hover {
  background-color: rgb(200, 220, 250);
  color: teal;
}

.golferModalOptionRow button.active,
.golferModalOptionRow button:focus,
.show > .golferModalOptionRow_dropdowntoggle,
.golferModalOptionRow_dropdowntoggle:active {
  background-color: rgb(0, 154, 225) !important;
  color: rgb(220, 230, 250) !important;
  box-shadow: unset !important;
}

.golferModalOptionRow .dropdown-menu {
  min-width: 5rem;
}

.golferModalOptionRow .dropdown-item {
  color: rgb(75, 165, 223);
  font-size: 0.8rem;
}

.golferModalOptionRow_search {
  width: 80%;
}

.golferModalTableContainer {
  height: 250px;
  overflow: scroll;
}

.golferModalTable {
  cursor: default;
}

.golferModalTable th, .golferModalTable td {
  padding: 0.5rem;
}

.golferModalTableHeader {
  color: #999;
  font-size: 0.5rem;
  text-transform: uppercase;
}

.golferModalTableHeader_centeritem {
  text-align: center;
}

.golferModalTableRow {
  font-size: 0.75rem;
  text-align: right;
}

.golferModalTableRow_leftcell {
  text-align: left;
}

.golferModalTableRow_centercell {
  text-align: center;
}

.scoreCardContainer{
  background-color: ghostwhite;
  border-top: 1px solid lightgrey;
  overflow: auto;
}

.scoreCardHeader{
  font-size: .8rem;
  font-weight: 700;
  padding-left: 3px;
}

.scoreCardLegendTable{
  font-size: 0.65rem;
  margin-bottom: .25rem;
  margin-right: 1rem;
}



.scoreCardLegendTable th, .scoreCardLegendTable td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
    text-align: left;
    white-space: nowrap;
    min-width: 20px;
    border-top: 0px solid;
}

.scoreCardTable{
  font-size: 0.6rem;
}

.scoreCardTable th, .scoreCardTable td {
    padding: 0.25rem;
    text-align: center;
    min-width: 20px;
}

.scoreColSpcl{
  font-size: 0.6rem;
  font-weight: 600;
}

.scoreCardHoleRow{
  background-color: grey;
  color: white;
}

.scoreCardScoreRow{
  font-weight: 400;
}

.birdie{
  background-color: darkseagreen !important;
}

.eagle{
  background-color: gold;
}

.bogey{
  background-color: lightsalmon;
}

.bogeyDouble{
  background-color: coral;
}

.liveScoreCardHeader{
  padding : 3px;
  font-size: 14px;
}
.liveScoreCard{
  border: 1px solid gainsboro;
}

/*! ========================================================================
 * Bootstrap Toggle: bootstrap2-toggle.css v2.2.0
 * http://www.bootstraptoggle.com
 * ========================================================================
 * Copyright 2014 Min Hur, The New York Times Company
 * Licensed under MIT
 * ======================================================================== */


label.checkbox .toggle,
label.checkbox.inline .toggle {
	margin-left: -20px;
	margin-right: 5px;
}

.toggle {
	min-width: 40px;
	height: 20px;
	position: relative;
	overflow: hidden;
	border-radius: 15px;
}

.toggle-group {
	position: absolute;
	width: 200%;
	top: 0;
	bottom: 0;
	left: 0;
	transition: left 0.35s;
	-webkit-transition: left 0.35s;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	    user-select: none;
}

.toggle.off .toggle-group {
	left: -100%;
}

.toggle-on {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 50%;
	margin: 0;
	border: 0;
	border-radius: 0;
}

.toggle-off {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	right: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
}

.toggle-handle {
	position: relative;
	margin: 0 auto;
	padding-top: 0px;
	padding-bottom: 0px;
	height: 100%;
	width: 0px;
	border-width: 0 1px;
	background-color: white;
}

.toggle-handle.btn-mini  {
	top: -2px;
}

.toggle-handle.btn-xs {
	top: -2px;
}

.toggle.btn { min-width: 30px; }
.toggle-on.btn { padding-right: 24px; display: flex; align-items: center; justify-content: center;}
.toggle-off.btn { padding-left: 24px; display: flex; align-items: center; justify-content: center;}

.toggle.btn-large { min-width: 40px; }
.toggle-on.btn-large { padding-right: 35px; }
.toggle-off.btn-large { padding-left: 35px; }

.toggle.btn-lg { min-width: 40px; }
.toggle-on.btn-lg { padding-right: 35px; }
.toggle-off.btn-lg { padding-left: 35px; }


.toggle.btn-small { min-width: 25px; }
.toggle-on.btn-small { padding-right: 20px; }
.toggle-off.btn-small { padding-left: 20px; }

.toggle.btn-sm { min-width: 25px; }
.toggle-on.btn-sm { padding-right: 20px; }
.toggle-off.btn-sm { padding-left: 20px; }

.toggle.btn-mini { min-width: 20px; }
.toggle-on.btn-mini { padding-right: 12px; }
.toggle-off.btn-mini { padding-left: 12px; }

.toggle.btn-xs { min-width: 20px; }
.toggle-on.btn-xs { padding-right: 12px; }
.toggle-off.btn-xs { padding-left: 12px; }

.slow .toggle-group { transition: left 0.7s; -webkit-transition: left 0.7s; }
.fast .toggle-group { transition: left 0.1s; -webkit-transition: left 0.1s; }
.quick .toggle-group { transition: none; -webkit-transition: none; }

.toggle-on[disabled], .toggle-on.disabled { cursor: not-allowed!important }
.toggle-off[disabled], .toggle-on.disabled { cursor: not-allowed!important }

.toggle-font-size-small {font-size: .85em}

.promoBannerDesktop{
    height:70px;
    width: 100%; /* fall back */
    background-position:center center;
    /*background-image:url(https://media.majorschallenge.com/leaderboardAdvBanner/regularLeagueDesktop.png);*/
    background-repeat:no-repeat;
    background-size:contain;
}

.promoBannerMobile{
    height:70px;
    width: 100%; /* fall back */
    background-position:center center;
    /*background-image:url(https://media.majorschallenge.com/leaderboardAdvBanner/regularLeagueMobile.png);*/
    background-repeat:no-repeat;
    background-size:contain;
}

.linkComponent {
  cursor: pointer;
  color: #007bff;
}

.linkComponent:hover {
  text-decoration: underline;
  /* color: #00008b; TODO this is incorrect, and this value does not appear to be standard across OS/browser */
}


.cupTeamRowRank.winner {
  font-size: 0.9em;
  /* font-weight: bold; */
  display: inline-block;
  height: 2em;
  width: 2em;
  line-height: 2em;
  border-radius: 1em;

  background-color: #2ecc71;
  border-color: #2ecc71;
  color: #ffffff;

  border: 1px solid #d9dde0;
  text-align: center;
}

.cupTeamRowRank:not(.winner) {
  font-size: 0.9em;
    /* font-weight: bold; */
}

.cupTeamRowBody.personal {
  background-color: rgba(52, 152, 219, 0.4);
}

.cupTeamRowBase {
  font-family: "Roboto";
  color: #515356;
  font-weight: normal;
  white-space: nowrap;
  vertical-align: middle!important;
}

.memberSearchInput::-webkit-input-placeholder{
  font-size: 0.7rem;
}
.memberSearchInput::-moz-placeholder{
  font-size: 0.7rem;
}
.memberSearchInput:-ms-input-placeholder{
  font-size: 0.7rem;
}
.memberSearchInput::-ms-input-placeholder{
  font-size: 0.7rem;
}
.memberSearchInput::placeholder{
  font-size: 0.7rem;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.pickTeam.container-fluid {
  max-width: 1128px;
  padding-left: 0px;
  padding-right: 0px;
}

.pickTeamContainer.container-fluid {
  max-width: 1080px;
  padding-left: 0px;
  padding-right: 0px;
}

.pickTeamHeaderContent {

}

.pickTeamMainContent {
  flex-wrap: nowrap;
  display: flex;
  overflow: auto;
}

.teamSlotEmpty {
  min-width: 150px;
  height: 64px;
  background-color: #eee;
  color: lightgray;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  border: 1px solid #d9dde0;
}

.teamSlotEmpty.teamSlotAlternate {
  opacity: 0.6;
  border-style: dashed;
}

.teamSlotEmpty_icon {
}

.teamSlotEmpty_text {
  padding-left: 1rem;
  color: #94a1ab;
  line-height: 64px;
}

.teamSlotEmpty.teamSlotAlternate .teamSlotEmpty_text {
  color: dimgray;
}

.teamSlotGolfer {
  min-width: 150px;
  height: 64px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  border: solid 1px #2ecc71;
}

.teamSlotGolfer.teamSlotAlternate {
  color: #94a1ab;
  border-style: dashed;
}

.teamSlotGolfer_img {
  height: 62px;
}

.teamSlotGolfer.teamSlotAlternate .teamSlotGolfer_img {
  opacity: 0.6;
}

.teamSlotEmpty .teamSlotGolfer_img {
  height: 62px;
}

.teamSlotGolfer_name {
  margin-top: 0.6rem;
  margin-bottom: 0.1rem;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.teamSlotGolfer_name:hover {
  cursor: pointer;
  color: blue;
}

.teamSlotGolfer_name.teamSlotGolfer_name__short {
  max-width: 148px
}

.teamSlotGolfer_name_option {
  margin-top: 0.6rem;
  margin-bottom: 0.1rem;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}



.teamSlotGolfer_meta {
  font-size: 0.70rem;
  white-space: nowrap;
  overflow: visible;
  color: #94a1ab;
}

.teamSlotGolfer_minus {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 26px;
  font-weight: 100;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #e74c3c;
  color: #e74c3c;
  line-height: 21px;
  text-align: center;
  box-sizing: border-box;
}

.teamSlotGolfer_minus:hover {
  cursor: pointer;
  background-color: #e74c3c;
  color: #ffffff;
}


.availableGolfer {
  min-width: 150px;
  height: 64px;
  border-bottom: 1px solid gray;
  padding-left: 0.8rem;
  padding-right: 0.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  margin-bottom: 0.5rem;
}

.availableGolfer_name {
  margin-bottom: 0.1rem;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
}

.availableGolfer_name:hover {
  cursor: pointer;
  color: blue;
}

.availableGolfer_meta {
  color: #999;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: visible;
}

.availableGolfer_plus {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 26px;
  font-weight: 100;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #27ae60;
  color: #27ae60;
  line-height: 21px;
  text-align: center;
  box-sizing: border-box;
}

.availableGolfer_plus:hover {
  cursor: pointer;
  background-color: #27ae60;
  color: #ffffff;
}

.availableGolfer_minus {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 26px;
  font-weight: 100;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #e74c3c;
  color: #e74c3c;
  line-height: 21px;
  text-align: center;
  box-sizing: border-box;
}

.availableGolfer_minus:hover {
  cursor: pointer;
  background-color: #e74c3c;
  color: #ffffff;
}



@media (max-width: 991px) {
  .teamSlotEmpty_text {
    font-size: 0.9rem;
  }

  .availableGolfer_name {
    font-size: 0.9rem;
  }
  .teamSlotGolfer_meta {
    font-size: 0.6rem;
  }
}

.pickTeamEntries {
}

.pickTeamEntriesCell {

}

.pickTeamEntriesCell_team {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pickTeamEntriesCell_roster {
  font-size: 0.75rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.deleteEntryIcon {
  /* position: absolute; */
  /* right: 12px; */
  /* top: 0px; */
  /* color: #ddd; */
  /* z-index: 10; */
}

/* Renders a multiplication (close) sign */
/* .deleteEntryIcon:after {
  display: inline-block;
  font-weight: 300;
  font-size: 2rem;
  content: "\00d7";
} */

.deleteEntryIcon:hover {
  color: red;
  cursor: pointer;
}

.pickTeamPickGroup {
  min-width: 270px;
}

.pickTeamNameModal {

}

.pickTeamNameModal .modal-header {
  background: -webkit-gradient(linear, left top, right top, from(#305875), to(#34495E));
  background: -webkit-linear-gradient(left, #305875, #34495E);
  background: linear-gradient(to right, #305875, #34495E);
  color: #ffffff;
  letter-spacing: 1px;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.pickTeamNameModal .close {
  color: #ffffff;
}

.pickTeamNameModal_input {
  width: 100%;
  height: 2.5rem;
  font-size: 1.1rem;
  outline: 1px solid green;
}

.pickTeamOptionsDropdown.dropdown-menu.show {
  left: unset !important; /* Need to use important to override dropdown menu's inline styles */
  right: -35px;
}

.pickTeamPromoImageContainerMobile {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: calc(187.0 / 418.0 * 100%);
  overflow: hidden;
}

.pickTeamPromoImageContainerDesktop {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: calc(280.0 / 1460.0 * 100%);
  overflow: hidden;
}

.pickTeamPromoImageAspect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.teamSlotGolfer_meta > span:before {
    content: "•";
    display: inline-block;
    width: 1em;
    margin-left: 6px;
}

.teamSlotGolfer_meta > span:last-child:before {
    content: "";
    display: inline-block;
    width: auto;
    margin-left: 0px;
}

.availableGolfer_meta > span:before {
    content: "•";
    display: inline-block;
    width: 1em;
    margin-left: 6px;
}


.fa-duotone, .fad {
    position: relative;
    font-family: "Font Awesome 6 Duotone";
    font-weight: 900;
    letter-spacing: normal;
}

.fa-hand-holding-dollar:before{content:"\f4c0"}
.fa-hand-holding-dollar:after{content:"\f4c0\f4c0"}


.nextBtnText {
  margin-top: 6px;
  font-size: 14px;
  margin-right: 2px;
}

.backBtnText {
  margin-top: 6px;
  font-size: 14px;
  margin-left: 2px;
}
/*
.no-border > div{
  border-width: 0px !important;
  border-color: white !important;
  box-shadow: 0px 0px 0px 0px !important;
}

.no-border .css-1pahdxg-control{
  border-width: 0px;
  box-shadow: 0px 0px 0px 0px !important;
}*/

/*Stop modal from scrolling*/
body.modal-open {
  position:static !important;
  overflow:visible;
  padding:0 !important;
}

.eventsWrapper {
  padding-top: 60px;
  padding-bottom: 2rem;
  background-color: rgba(236, 240, 241, 0.5);
  min-height: 100vh;
}

.eventsHeaderContainer {
  width: 100%;
  height: 42px;
  background-color: #2c3e50;
  display: inline-block;
  text-align: center;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.eventsHeader {
  display: flex;
  overflow-x: auto;
  flex-direction: row;
  margin-bottom: -20px;
  padding-bottom: 20px;
  padding-left: 0px;
}

.eventsHeader_item {
  display: inline-block;
  height: 42px;
  max-width: 12rem;
  text-align: left;
  color: lightgray;
  padding-top: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  line-height: 1;
  cursor: pointer;
  text-decoration: none !important;
  white-space: nowrap;
  -webkit-user-drag: none;
}

.eventsHeader a:last-child {
}

.eventsHeader_item__active {
  border-bottom: 3px solid white;
}
.eventsHeader_item:hover .eventsHeader_itemcell {
  color: #ffffff;
}

.eventsHeader_itemcell {
  font-size: 0.8rem;
  /*color: #94a9be;*/
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.3px;
  text-align: center;
  text-decoration: none;
  list-style: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.eventsHeader_item.eventsHeader_item__active .eventsHeader_itemcell {
  /*color: #ffffff;*/
}

.eventsHeader_itembadge {
}

.eventsContainer {
  font-size: 0.9rem;
  padding-top: 1rem;
}

.step {
    text-align: center;
    font-weight: bolder;
    color: #999;
    width: calc(100% / 3);
}

.step.activeStep {
    color: #28a745;
}

.stepIndex {
    display: block;
    height: 3em;
    width: 3em;
    line-height: 2.8em;

    moz-border-radius: 50%;
    border-radius: 50%;

    border: 2px solid #999;
    color: #999;
}

.stepIndex:hover {
  cursor: pointer;
}

.activeStep .stepIndex {
    border: 2px solid #28a745;
    background-color: #28a745;
    color: #fff;
}

.stepTitle {
    font-size: .7em;
    text-transform: uppercase;
}

.nextButton {
    text-transform: uppercase;
    /* color: #e8e8e8; */
    /* background-color: #666; */
    /* height: 3em; */
}

.contentTitle {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: #333;
    font-size: 1.25em;
}

.charityContent .contentTitle {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: #333;
    font-size: 1.1em;
}

.poolOption {
    color: #666;
    background-color: #eeeeee;
}

.poolOption:active, .poolOption.active {
    color: #e8e8e8;
    background-color: #666;
}

@media (max-width: 991px) {
  .poolOption {
    font-size: 0.75rem;
  }
}

.flash-message {
  border-radius: 10px;
  position: fixed;
  top: 80px;
  right: 10px;
  padding: 12px;
  display: flex;
  align-items: center;
  z-index: 1111;
  border: 1px solid;
}

.flash-error {
  background: lightcoral;
  border: 1px solid red;
}

.flash-success {
  background: #CDF9DA;
  border: 1px solid green;
}

.editModal form label {
  width: 100%;
}

.editModal form label input {
  width: inherit;
}

.creditBalanceRow {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.creditBalanceRow button {
  border-color: rgb(96, 174, 227);
  background-color: white;
  color: rgb(75, 165, 223);
  min-width: 50px;
  margin-left: 10px !important;
  border-radius: 0.2rem !important;
}

.creditBalanceRow button:hover {
  background-color: rgb(200, 220, 250);
  color: teal;
}

.creditBalanceRow button.active,
.creditBalanceRow button:focus {
  border-color: orange !important;
  border-width: 2px !important;
  background-color: white !important;
  color: rgb(75, 165, 223) !important;
  font-weight: 600;
}

.creditBalanceRow input {
    border-color: rgb(96, 174, 227);
    background-color: white;
    color: rgb(75, 165, 223);
    border-radius: 0.2rem;
    width: 70px;
    margin-left: 10px !important;
    text-align: center;
}

.creditBalanceRow input.active {
  border-color: orange !important;
  border-width: 2px !important;
}

.creditBalanceRow input::-webkit-input-placeholder {
    font-size: .875rem;
    line-height: 1.5;
}

.creditBalanceRow input::-moz-placeholder {
    font-size: .875rem;
    line-height: 1.5;
}

.creditBalanceRow input:-ms-input-placeholder {
    font-size: .875rem;
    line-height: 1.5;
}

.creditBalanceRow input::-ms-input-placeholder {
    font-size: .875rem;
    line-height: 1.5;
}

.creditBalanceRow input::placeholder {
    font-size: .875rem;
    line-height: 1.5;
}

.teamModalBody{
  font-family: Roboto;
  font-size: 14px;
}

.teamModalGroup{
  font-weight: 600;

}

.tournamentHeader {
  background-color: #383838;
  text-transform: uppercase;
  color: #e8e8e8;
  font-family: Roboto;
  font-size: 0.8em;
}

.tournamentHeader .subtitle {
  color: #aaa;
  font-size: 0.6em;
  font-weight: bolder;
}

.payoutsHeader {
  background-color: #f6f6f6;
  color: #aaa;
  text-transform: uppercase;
  font-size: 0.7rem;
}

.file_upload_div {
  position: relative;
  overflow: hidden;
}
.file_upload_div_input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.public-DraftStyleDefault-block {
  margin: 2px !important;
}

.gamesContent {
}

.gamesContent .row {
  padding-bottom: 1rem;
}

.gamesContent h5 {
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 900;
  line-height: 22px;
  color: #212121;
}

.gamesContent h6 {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: bold;
  line-height: 22px;
  color: #212121;
}

.gamesContent p, .gamesContent li {
  font-size: 14px;
  font-family: "Roboto";
  line-height: 22px;

}

.gamesContentTitle {
  font-family: "Vollkorn", serif;
  font-size: 29px;
  font-weight: 700;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
}

.gamesContentSubTitle {
  font-family: "Vollkorn", serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
}


.richTextEditor p{
  line-height: normal !important;
}

.richTextEditor p span{
  margin-bottom: 3px;
}

.foundationContent p, .foundationContent li {
  font-size: 14px;
  font-family: "Roboto";
  line-height: 22px;
}

.foundationContent li {
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .ruleStepNumberCircle {
    left: -0.5em;
  }

  .gamesContentTitle {
    font-size: 25px;
  }

  .gamesContentSubTitle {
    font-size: 21px;
  }

  .gamesContent h5 {
    font-size: 18px;
  }

}

.commissionerWrapper {
  padding-top: 60px;
  background-color: rgba(236, 240, 241, 0.5);
  min-height: 100vh;
}

.commissionerTitle {
  font-family: Roboto;
  font-size: 2rem;
}

.commissionerContentTitle {
  font-family: Roboto;
  font-size: 1.5rem;
}


.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  max-width: 500px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.commissionerWrapper .form-control::-webkit-input-placeholder {
  color: #aaa;
}

.commissionerWrapper .form-control::-moz-placeholder {
  color: #aaa;
}

.commissionerWrapper .form-control:-ms-input-placeholder {
  color: #aaa;
}

.commissionerWrapper .form-control::-ms-input-placeholder {
  color: #aaa;
}

.commissionerWrapper .form-control::placeholder {
  color: #aaa;
}

.commissionerWrapper .estimator {
  font-size: 1rem;
}

@media (max-width: 991px) {
  .commissionerWrapper .estimator {
    font-size: 0.9rem;
  }
}

.commissionerMembers .btn.btn-outline-primary:focus,
.commissionerMembers .btn.btn-outline-primary.active:focus {
  box-shadow: none;
}

.commissionerMembersHeader {
  overflow-x: auto;
}

.commissionerNavGroup {
  pointer-events: none;
  cursor: pointer;
  font-size: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
  padding-left: 0.7rem;
  padding-top: .8rem;
  padding-bottom: .65rem;
  font-weight: 700;
  /*background-color: grey;
  color: white;*/
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  margin-top: 20px;
}

.fieldModalHeader {
  background-color: #383838;
  text-transform: uppercase;
  color: #e8e8e8;
  font-family: Roboto;
  font-size: 0.8em;
}

.fieldModalHeader .subtitle {
  color: #aaa;
  font-size: 0.6em;
  font-weight: bolder;
}

.payoutsHeader {
  background-color: #f6f6f6;
  color: #aaa;
  text-transform: uppercase;
  font-size: 0.7rem;
}

.adminWrapper {
  padding-top: 60px;
  background-color: rgba(236, 240, 241, 0.5);
  min-height: 100vh;
}

.adminTitle {
  font-family: Roboto;
  font-size: 2rem;
}

.adminContentTitle {
  font-family: Roboto;
  font-size: 1.5rem;
}

.createClubForm {
  font-family: "Roboto";
}
.createClubForm .form-control {
  font-family: "Roboto";
  font-weight: 500;
  /*color: #28a745;*/
}

.createClubForm .form-control::-webkit-input-placeholder {
  font-family: "Roboto";
  font-weight: 500;
}

.createClubForm .form-control::-moz-placeholder {
  font-family: "Roboto";
  font-weight: 500;
}

.createClubForm .form-control:-ms-input-placeholder {
  font-family: "Roboto";
  font-weight: 500;
}

.createClubForm .form-control::-ms-input-placeholder {
  font-family: "Roboto";
  font-weight: 500;
}

.createClubForm .form-control::placeholder {
  font-family: "Roboto";
  font-weight: 500;
}

.createClubForm .contentTitle {
  font-size: 1.1rem;
}



.my_custom_off {
  color: #fff;
  background-color: rgb(41, 182, 246);
  border-color: rgb(2, 136, 209);
  color: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.my_custom_off:hover {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: white;
  background-color: rgb(2, 136, 209);
  border-color: #01579b;
}

.leaderboardRowPromoBannerDesktop{
    height:70px;
    width: 100%; /* fall back */
    background-position:center center;
    /*background-image:url(https://media.majorschallenge.com/leaderboardAdvBanner/regularLeagueDesktop.png);*/
    background-repeat:no-repeat;
    background-size:contain;
}

.leaderboardRowPromoBannerMobile{
    height:70px;
    width: 100%; /* fall back */
    background-position:center center;
    /*background-image:url(https://media.majorschallenge.com/leaderboardAdvBanner/regularLeagueMobile.png);*/
    background-repeat:no-repeat;
    background-size:contain;
}

.fieldCompareTable td{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

}

.sportradarlogo {
    height: 25px;
    width: 70px;
    background: url('https://www.sportradar.com/choose_region/sportradar-logo.svg') no-repeat 0px 10px/60px;
}

.mclogo {
    height: 25px;
    width: 70px;
    background: url('/static/media/brand.59ecf122.png') no-repeat 0px 10px/50px;
}

.tournamentSeason_dropdown  .dropdown-menu {
  min-width: 2rem;
}

@media (max-width: 991px) {
  .topNavWrapper .leagueNavContainer {
    flex-direction: column;
    height: auto;
    line-height: 32px;
  }
  .topNavWrapper .leagueNavItem_link.leagueNavItem_link__active {
    border-bottom: none;
  }
}

.topNav {
  background-color: #34495e;
  color: #ffffff;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3);
}

.topNavContainer {
  background-color: #34495e;
  width: 100%;
}

.topNavTitle {
  font-family: 'Archivo', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 0.86;
  text-align: left;
  margin-bottom: 0px;
}

.topNavTitle_majors {
  color: #2ecc71;
}

.topNavTitle_challenge {
  color: #ffffff;
}

.topNavProfile {
  color: #94a9be;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid #94a9be;
  padding: 0.3rem 0.3rem;
  border-radius: 4px;
  width: 83px;
}

.topNavProfile:hover {
  color: #ffffff;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.topNavProfile_label {
  font-family: 'Roboto Condensed', 'Roboto', 'sans-serif';
  font-size: 0.8rem;
  letter-spacing: 0.3px;
  padding-left: 0.25rem;
}

.topNavProfile_chevron {
  font-size: 0.75rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.navbar-dark .topNav_toggler.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .topNav_toggler.navbar-toggler > .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.leagueDropdownContainer {
  white-space: nowrap;
}

.leagueDropdownContainer > .dropdown-menu.show {
  top: 75%;
  display: inline-block;
}

.leagueDropdown,
.leagueDropdown:hover,
.leagueDropdown:active,
.leagueDropdown:focus {
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  letter-spacing: 0.5px;
  line-height: 42px;
}

.leagueNavContainer {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  /* text-align: center; */
  height: 60px;
  line-height: 64px;
}

.leagueNavItem {
  display: inline-block;
  font-family: 'Roboto Condensed', 'Roboto', 'sans-serif';
  font-size: 0.9rem;
  font-weight: normal;
  letter-spacing: 0.6px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

a.leagueNavItem_link {
  color: #94a9be;
  text-decoration: none;
}

.leagueNavItem_link.leagueNavItem_link__active,
a.leagueNavItem_link:active,
a.leagueNavItem_link:hover,
a.leagueNavItem_link:focus {
  color: #ffffff;
  text-decoration: none;
}

.leagueNavItem_link.leagueNavItem_link__active {
  border-bottom: 4px solid #3498db;
}

.btn-no-border {
  border: none !important;
}

.btn-only-buttom-border {
  border: none !important;
  border-bottom: 1px solid !important;
}


