.renderComponent {
  cursor: pointer;
  color: #007bff;
}

.renderComponent:hover {
  text-decoration: underline;
  /* color: #00008b; TODO this is incorrect, and this value does not appear to be standard across OS/browser */
}

.rulesPromoImageContainer {
  position: relative;
  height: 0;
  width: 100%;
  max-width: 420px;
  padding-top: calc(187.0 / 418.0 * 100%);
  overflow: hidden;
}

.rulesPromoImageContainer > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
