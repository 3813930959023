.commissionerWrapper {
  padding-top: 60px;
  background-color: rgba(236, 240, 241, 0.5);
  min-height: 100vh;
}

.commissionerTitle {
  font-family: Roboto;
  font-size: 2rem;
}

.commissionerContentTitle {
  font-family: Roboto;
  font-size: 1.5rem;
}


.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  max-width: 500px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.commissionerWrapper .form-control::placeholder {
  color: #aaa;
}

.commissionerWrapper .estimator {
  font-size: 1rem;
}

@media (max-width: 991px) {
  .commissionerWrapper .estimator {
    font-size: 0.9rem;
  }
}

.commissionerMembers .btn.btn-outline-primary:focus,
.commissionerMembers .btn.btn-outline-primary.active:focus {
  box-shadow: none;
}

.commissionerMembersHeader {
  overflow-x: auto;
}

.commissionerNavGroup {
  pointer-events: none;
  cursor: pointer;
  font-size: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
  padding-left: 0.7rem;
  padding-top: .8rem;
  padding-bottom: .65rem;
  font-weight: 700;
  /*background-color: grey;
  color: white;*/
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  margin-top: 20px;
}
