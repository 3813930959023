.facebookAuthLoginButton {
  color: white;
  background-color: #3b5998;
  border-radius: 3px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 180px;
}

.facebookAuthLoginButton:hover {
  cursor: pointer;
  background-color: #31497d;
}

.facebookAuthLoginIcon {
  font-size: 1.5rem;
  margin-right: 0.25rem;
}

.facebookAuthLoginIconText {
  font-size: 0.8rem;
  vertical-align: text-bottom;
  font-family: Roboto;
}

.googleAuthLoginButton {
  color: white;
  background-color: #4285F4;
  font-size: 1.2rem;
  border-radius: 3px;
  text-align: center;
  width: 180px;
}

.googleAuthLoginButton:hover {
  cursor: pointer;
  background-color: #366ad3;
}

.googleAuthLoginButtonImgContainer {
  background-color: #fff;
  border-radius: 2px;
  width: 2.0rem;
  height: 1.8rem;
  margin-right: 0.2rem;
  padding: 2px;
}

.googleAuthLoginButtonImg {
  height: 1.5rem;
  margin-bottom: 0.35rem;
}

.googleAuthLoginIconText {
  font-size: 0.8rem;
  vertical-align: text-bottom;
  font-family: Roboto;
  margin-top: 5px;
}
