.landingFooter {
  height: 200px;
  background-color: #2c3e50;
  color: #ffffff;
  align-items: start;
  padding-top: 2rem;
}

.landingFooterMobile {
  height: 60px;
  background-color: #2c3e50;
  color: #ffffff;
}

@media (max-width: 991.98px) {
  .landingFooter .container {
    width: 100%;
    max-width: none;
  }
}

.landingFooter_heading {
  font-family: 'Roboto';
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #ffffff !important;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 30px;
}

.landingFooter_link {
  font-family: 'Roboto';
  color: #90a4ae;
  white-space: nowrap;
  line-height: 30px;
}

.landingFooter_link:hover {
  color: #ffffff;
  text-decoration: none;
}

.landingFooter .loginLink {
  color: #90a4ae;
}
.landingFooter .loginLink:hover {
  color: #ffffff;
  text-decoration: none;
}


.landingFooter_copyright {
  color: #90a4ae;
  font-family: 'Roboto';
  font-size: 14px;
  letter-spacing: 0.3px;
}
