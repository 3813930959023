.inviteModal form div div {
  width: 100%;
}

.inviteModal form div div input {
  width: inherit;
}

.inviteModal .valid .email {
  border-color: lightgreen;
}

.inviteModal .invalid .email,
.inviteModal .current .email {
  border-color: red;
}

.inviteModal .feedback {
    color: red;
    font-size: .85rem;
}

.inviteModal form div div textarea {
  width: inherit;
}

.multiEmails{
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto;
}
.inviteModal .addInvitee {
    color: #0af;
    cursor: pointer;
}


.react-multi-email.empty > span[data-placeholder] {
    display: contents !important;
    color: #ccc;
}

.react-multi-email > input {
  width: 100% !important;
}


.validBar{
  background-color:green;
  margin-right:0.4em;
  display:inline-block;
}

.invalidBar{
  background-color:red;
  margin-right:0.4em;
  display:inline-block;
}

.invitedBar{
  background-color:firebrick;
  margin-right:0.4em;
  display:inline-block;
}

.currentBar{
  background-color:coral;
  margin-right:0.4em;
  display:inline-block;
}

.legend{
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
}


.react-multi-email > input {
  max-width:300px !important;
}
