@media (max-width: 991px) {
   .leaderboardContainer {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      min-width: 100%;
   }
}

.leaderboardContainer {
  max-width: 1200px;
}

.mcLeaderboardHeaderTitle {
  padding-left: 0.75rem;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 500;
  color: #232323;
  white-space: nowrap;
}

.mcLeaderboardHeaderSubtitle {
  padding-left: 0.75rem;
  color: #5b6c77;
}


.mcLeaderboardHeaderWinnings {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  color: #5b6c77;
  text-align: left;
  white-space: nowrap;
}

.mcLeaderboardHeaderPointpool {
  font-family: "Roboto";
  font-size: 14px;
  color: #5b6c77;
  text-align: left;
  white-space: nowrap;
}

.mcLeaderboardHeaderPointpoolValue {
  font-weight: 500;
  color: #232323;
}

.mcLeaderboardTable > thead > tr > th {
  font-family: "Roboto";
  font-size: 0.9em;
  border-top-color: #ecf0f1;
  border-bottom: none;
  background-color: #ecf0f1;
  color: #5b6c77;
  text-transform: uppercase;
}

.mcLeaderboardTable > thead > tr > td {
  font-family: "Roboto";
  border-top-color: #ecf0f1;
  border-bottom: none;
}

.mcLeaderboardTeamRowBody.friend,
.mcLeaderboardTeamRowBody.expanded {
  background-color: rgba(52, 152, 219, 0.2);
}

.mcLeaderboardTeamRowBody.personal,
.mcLeaderboardTeamRowBody.expanded.friend {
  background-color: rgba(52, 152, 219, 0.4);
}

.mcLeaderboardTeamRowBody.host,
.mcLeaderboardTeamRowBody.expanded.host {
  background-color: #EEDC82;
}

.mcLeaderboardTeamRowRank.winner {
  font-size: 0.9em;
  /* font-weight: bold; */
  display: inline-block;
  height: 2em;
  width: 2em;
  line-height: 2em;

  -moz-border-radius: 1em;
  border-radius: 1em;

  background-color: #2ecc71;
  border-color: #2ecc71;
  color: #ffffff;

  border: 1px solid #d9dde0;
  text-align: center;
}

.mcLeaderboardTeamRowRank:not(.winner) {
  font-size: 0.9em;
    /* font-weight: bold; */
}
/* .personal .mcLeaderboardTeamRowRank {
    color: #3498db;
    border-color: #3498db;
}

.mcLeaderboardTeamRowRank.winner {
  background-color: #2ecc71;
  border-color: #2ecc71;
  color: #ffffff;
} */

/* .personal .mcLeaderboardTeamRowRank.winner {
    background-color: #3498db;
    border-color: #3498db;
    color: #ffffff;
} */

.mcLeaderboardTeamRowTeam {
  font-family: "Roboto";
  font-weight: normal;
  color: #232323;
  white-space: nowrap;
}

.mcLeaderboardTeamRowBase {
  font-family: "Roboto";
  color: #515356;
  font-weight: normal;
  white-space: nowrap;
  vertical-align: middle!important;
}

.pgaLeaderboardTitle {
  font-family: "Roboto";
  color: #232323;
  font-weight: 500;
  font-size: 18px;
}

.pgaLeaderboardSubtitle {
  font-family: "Roboto";
  color: #5b6c77;
  font-size: 12px;
  text-align: right;
}

.pgaLeaderboardRow {
  font-size: 0.85em;
}

.pgaLeaderboardRow.personal {
  background-color: rgba(52, 152, 219, 0.2);
}

.pgaLeaderboardRow.others {
  background-color: #F0F0E0;
}

.pgaLeaderboardPlayer {
  font-family: "Roboto";
  color: #232323;
  white-space: nowrap;
}

.pgaLeaderboardPlayer:hover {
  cursor: pointer;
  color: blue;
}

.pgaLeaderboardTable > thead > tr > th,
.pgaLeaderboardTable > tbody > tr > td {
  /*border-top-color: #ecf0f1;*/
  border-bottom: none;

}

.leaderboardPromoImageContainer {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: calc(240.0 / 600.0 * 100%);
  overflow: hidden;
}

.leaderboardPromoImageAspect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.leaderboardRowPromoBannerDesktop{
    min-height:70px;
    width: 100%; /* fall back */
    background-position:center center;
    /*background-image:url(https://media.majorschallenge.com/leaderboardAdvBanner/regularLeagueDesktop.png);*/
    background-repeat:no-repeat;
    -webkit-background-size:contain;
    -moz-background-size:contain;
    -o-background-size:contain;
    background-size:contain;
}

.leaderboardRowPromoBannerMobile{
    height:70px;
    width: 100%; /* fall back */
    background-position:center center;
    /*background-image:url(https://media.majorschallenge.com/leaderboardAdvBanner/regularLeagueMobile.png);*/
    background-repeat:no-repeat;
    -webkit-background-size:contain;
    -moz-background-size:contain;
    -o-background-size:contain;
    background-size:contain;
}

.paginationBtn:hover{
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
