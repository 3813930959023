.file_upload_div {
  position: relative;
  overflow: hidden;
}
.file_upload_div_input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
