@media screen and (max-width: 600px) {
  .d-mob-potrait {
    display: block !important;
  }


}


.oadFullScheduleDesktop{
  min-width: 700px;
}

.golferModalContainer {
  max-width: 650px;
}

.golferModal {
  min-height: 500px;
}

.golferModalInjuryHeader{
  background: lightpink;
  box-shadow: 0px 1px 1px pink;
}

.golferModalInjuryHeader_label{
  margin-left: 0.8rem;
  font-size: 0.8rem;
  font-weight: bold;
}

.golferModalInjuryHeader_value{
  margin-left: 3px;
  font-size: .8rem;
  font-weight: bold;
  color: firebrick;

}

.golferModalHeader {
  background: linear-gradient(to right, #305875, #34495E);
  box-shadow: 0px 1px 1px gray;
}

.golferModalHeader:hover {
  cursor: default;
}

.golferModalHeader_imgplaceholder {
  color: #ddd;
  font-size: 10rem;
  display: inline-block;
  width: 175px;
  height: 175px;
  text-align: center;
}

.golferModalHeader_content {
  margin-left: 0.5rem;
}
.golferModalHeader_name {
  color: #fff;
  margin-bottom: 0;
  margin-top: 1rem;
  font-kerning: ;
}

.golferModalHeader_subtitle {
  color: #aaa;
  font-size: 0.7rem;
}

.golferModalHeader_close {
  position: absolute;
  right: 5px;
  top: -10px;
  color: #ddd;
  z-index: 999;
  font-size: 2rem;
  font-weight: 200;
}
.golferModalHeader_close:after{
  display: inline-block;
  content: "\00d7"; /* Renders a multiplication sign */
}

.golferModalHeader_close:hover {
  cursor: pointer;
}

.golferModalHeaderDataRow {
  margin-top: 1rem;
}

.golferModalHeaderDataCell {
  line-height: 0.5;
}

.golferModalHeaderDataCellSmall {
  line-height: 0.5;
  margin-left: -0.7rem;
  margin-right: -0.7rem;
}

.golferModalHeaderDataCellLarge {
  line-height: 0.5;
  margin-right: 1.4rem;
}

.golferModalHeaderDataCell_value {
  color: #fff;
  font-size: 0.8rem;
  font-weight: normal;
  text-align: center;
  margin-bottom: 0;
}

.golferModalHeaderDataCell_label {
  color: #aaa;
  font-size: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
}

.golferModal .modal-body {
  padding: 10px;
}

.golferModalOptionRow {
  padding-bottom: 0.5rem;
}

.golferModalOptionRow button,
.golferModalOptionRow_dropdowntoggle {
  border-color: rgb(96, 174, 227);
  background-color: white;
  color: rgb(75, 165, 223);
}

.golferModalOptionRow button:hover {
  background-color: rgb(200, 220, 250);
  color: teal;
}

.golferModalOptionRow button.active,
.golferModalOptionRow button:focus,
.show > .golferModalOptionRow_dropdowntoggle,
.golferModalOptionRow_dropdowntoggle:active {
  background-color: rgb(0, 154, 225) !important;
  color: rgb(220, 230, 250) !important;
  box-shadow: unset !important;
}

.golferModalOptionRow .dropdown-menu {
  min-width: 5rem;
}

.golferModalOptionRow .dropdown-item {
  color: rgb(75, 165, 223);
  font-size: 0.8rem;
}

.golferModalOptionRow_search {
  width: 80%;
}

.golferModalTableContainer {
  height: 250px;
  overflow: scroll;
}

.golferModalTable {
  cursor: default;
}

.golferModalTable th, .golferModalTable td {
  padding: 0.5rem;
}

.golferModalTableHeader {
  color: #999;
  font-size: 0.5rem;
  text-transform: uppercase;
}

.golferModalTableHeader_centeritem {
  text-align: center;
}

.golferModalTableRow {
  font-size: 0.75rem;
  text-align: right;
}

.golferModalTableRow_leftcell {
  text-align: left;
}

.golferModalTableRow_centercell {
  text-align: center;
}

.scoreCardContainer{
  background-color: ghostwhite;
  border-top: 1px solid lightgrey;
  overflow: auto;
}

.scoreCardHeader{
  font-size: .8rem;
  font-weight: 700;
  padding-left: 3px;
}

.scoreCardLegendTable{
  font-size: 0.65rem;
  margin-bottom: .25rem;
  margin-right: 1rem;
}



.scoreCardLegendTable th, .scoreCardLegendTable td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
    text-align: left;
    white-space: nowrap;
    min-width: 20px;
    border-top: 0px solid;
}

.scoreCardTable{
  font-size: 0.6rem;
}

.scoreCardTable th, .scoreCardTable td {
    padding: 0.25rem;
    text-align: center;
    min-width: 20px;
}

.scoreColSpcl{
  font-size: 0.6rem;
  font-weight: 600;
}

.scoreCardHoleRow{
  background-color: grey;
  color: white;
}

.scoreCardScoreRow{
  font-weight: 400;
}

.birdie{
  background-color: darkseagreen !important;
}

.eagle{
  background-color: gold;
}

.bogey{
  background-color: lightsalmon;
}

.bogeyDouble{
  background-color: coral;
}

.liveScoreCardHeader{
  padding : 3px;
  font-size: 14px;
}
.liveScoreCard{
  border: 1px solid gainsboro;
}
