.gamesContent {
}

.gamesContent .row {
  padding-bottom: 1rem;
}

.gamesContent h5 {
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 900;
  line-height: 22px;
  color: #212121;
}

.gamesContent h6 {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: bold;
  line-height: 22px;
  color: #212121;
}

.gamesContent p, .gamesContent li {
  font-size: 14px;
  font-family: "Roboto";
  line-height: 22px;

}

.gamesContentTitle {
  font-family: "Vollkorn", serif;
  font-size: 29px;
  font-weight: 700;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
}

.gamesContentSubTitle {
  font-family: "Vollkorn", serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
}


.richTextEditor p{
  line-height: normal !important;
}

.richTextEditor p span{
  margin-bottom: 3px;
}

.foundationContent p, .foundationContent li {
  font-size: 14px;
  font-family: "Roboto";
  line-height: 22px;
}

.foundationContent li {
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .ruleStepNumberCircle {
    left: -0.5em;
  }

  .gamesContentTitle {
    font-size: 25px;
  }

  .gamesContentSubTitle {
    font-size: 21px;
  }

  .gamesContent h5 {
    font-size: 18px;
  }

}
